import React, { Component } from 'react';
import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import common from '../../common';
import Add from '../../public/icons/add.svg';
import debounce from 'lodash/debounce';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Mapped from '../../public/icons/link.png';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Profile from '../../public/icons/user-profile.svg';
import Invite from '../../public/icons/invite.svg';
import Password from '../../public/icons/password.svg';
import Resend from '../../public/icons/resend.svg';
import Lock from '../../public/icons/lock-small-icon.svg';
import Invitew from '../../public/icons/invite-w.svg';
import ImgHolder from '../../public/images/img-holder.svg';
import DownloadArrow from '../../public/icons/down-arrow.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Pagination from "react-js-pagination";
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import sampleFile from '../../public/images/bulk_student_create.csv';
import samplePasswordUpdateFile from '../../public/images/bulk_password.csv';
import reportsLoader from '../../public/icons/loader.png';
import Refresh from '../../public/icons/refresh.png';
import { Loader } from 'semantic-ui-react';




class Students extends Component {
  constructor(props) {
    super(props);
    this.state = {


      d_id: null,
      open: false,
      open2: false,
      opend: false,
      openf: false,
      id: null,
      image: null,
      imageURL: null,
      name: '',
      username: '',
      email: '',
      mobile: '',
      roll_no: '',
      password: '',
      section: '',
      classs: '',
      batch: '',
      filterClass: '',
      filterClassName: '',
      filterSection: '',
      filterSectionName: '',
      class_centers: {},
      class_center: '',
      center_id: localStorage.getItem('center_id'),
      branch: localStorage.getItem('center_id'),
      filterCenter: localStorage.getItem('center_id'),
      class_names: {},
      section_names: {},
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,

      centers: [],
      branches: [],
      classes: [],
      filterClasses: [],
      sections: [],
      students: [],
      current_page: 0,
      totalItems: 0,
      filterStudents: [],
      mail_type: 1,
      user_ids: [],
      dis_branch: '',
      dis_code: '',
      invite: false,
      resend: false,
      is_search: false,
      is_primary: '',
      class_batch_mapping: [],
      sections1: {},
      classListOnChange: [],
      hide: true,
      isDialogVisible: false,
      bulkUserJobData: [],
      bulkUserTableShow: false,
      bulkUserJobStatus: false,
      bulkUserJobCounter: 0,
      bulkUserJobStatusError: false,
      isPasswordDialogVisible: false,
      savePasswordValue: '',
      saveUserId: '',
      saveUserDefaultStatus: false,
      isActiveInactiveDialogVisible: false,

      isBulkPasswordDialogVisible: false,
      bulkPasswordUpdateJobData: [],
      bulkPasswordUpdateTableShow: false,
      bulkPasswordUpdateJobStatus: false,
      bulkPasswordUpdateJobCounter: 0,
      bulkPasswordUpdateJobStatusError: false,

      saveActiveInavtiveValue: '',
      setNewPassword: '',
      clearCacheLoading: false,

    }
    this.debouncedSearch = debounce(this.handleSearch, 1000);
  }

  componentDidMount() {

    let { class_batch_mapping } = this.state;
    var option = {
      "class_id": "",
      "batch_id": ""
    }

    class_batch_mapping.push(option);

    this.setState({ class_batch_mapping })
    if (localStorage.getItem("curruntPageNumber")) {
      this.getStudentList(this.state.center_id, this.state.filterClass, this.state.filterSection, parseInt(localStorage.getItem("curruntPageNumber")));
      localStorage.removeItem("curruntPageNumber");
    } else {
      this.getStudentList(this.state.center_id, this.state.filterClass, this.state.filterSection, 1);
    }
    //this.getStudentList(this.state.center_id,this.state.filterClass,this.state.filterSection,1);
    this.getCenterList();
    this.getClassList();
    this.getAdminProfile();
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = () => {
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if (modifyURL) {
      console.log("modifyURL", modifyURL);
      console.log("languagessss", language);
      this.fetchPageWiseData(language, modifyURL);
    }

  }

  fetchPageWiseData = async (language, modifyURL) => {
    const response = await this.props.getPageLanguageWiseData(language, modifyURL);
    if (response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData', JSON.stringify(response?.data))


    }
  }

  getAdminProfile = async () => {
    let response = await this.props.getAdminProfile();

    if (response !== undefined) {
      this.setState({ is_primary: response.data.is_primary })
    }
  }

  show = () => this.setState({
    open: true, name: '', email: '', mobile: '', branch: '',
    password: ''
  });

  close = () => this.setState({ open: false });

  /****************function called on edit *********************************/
  show2 = async (value) => {

    if (this.state.is_search === true) {
      const val = { "user": value._source, "id": value._source.student_id };

      this.setState({
        open2: true, id: val.user.student_id, user_id: val.user.user_id,
        name: val.user?.name === undefined || val.user?.name === null ? '' : val.user?.name,
        username: val.user.username === undefined || val.user.username === null ? '' : val.user.username,
        email: val.user?.email === undefined || val.user?.email === null ? '' : val.user?.email,
        mobile: val.user?.mobile === undefined || val.user?.mobile === null ? '' : val.user?.mobile,
        image: val.user?.profile_picture === undefined || val.user?.profile_picture === null
          ? '' : val.user?.profile_picture,
        roll_no: val.user.roll_no === undefined || val.user.roll_no === null ? '' : val.user.roll_no,
        imageURL: null, classs: val.user?.class_id === undefined ? ''
          : val.user?.class_id, branch: val.user?.center,
        section: val.user?.batch_id === undefined ? '' : val.user?.batch_id,
        dis_code: val.user?.roll_no === undefined || val.user?.roll_no === null ? '' : val.user?.roll_no,
        dis_branch: val.user?.center_label
      });
      if (val.user?.class_id !== undefined && val.user?.class_id != '' && val.user?.class_id != null) {
        this.getSectionList(this.state.class_centers[val.user?.class_id], val.user?.class_id);
      }

    } else {
      const val = value;

      this.setState({
        open2: true, id: val.id,
        name: val.user.name === undefined || val.user.name === null ? '' : val.user.name,
        username: val.user.username === undefined || val.user.username === null ? '' : val.user.username,
        email: val.user.email === undefined || val.user.email === null ? '' : val.user.email,
        mobile: val.user.mobile === undefined || val.user.mobile === null ? '' : val.user.mobile,
        image: val.user.profile_picture === undefined || val.user.profile_picture === null
          ? '' : val.user.profile_picture,
        roll_no: val.roll_no === undefined || val.roll_no === null ? '' : val.roll_no,
        imageURL: null, classs: val.class_details.id === undefined ? ''
          : val.class_details.id, branch: val.user.center,
        section: val.section_details.id === undefined ? '' : val.section_details.id,
        //filterClasses: this.state.classes.filter(classs => classs.center === val.user.center),
        dis_code: val.roll_no === undefined || val.roll_no === null ? '' : val.roll_no,
        dis_branch: val.user?.branch.name
      });
      if (val.class_details?.id !== undefined) {
        this.getSectionList(this.state.class_centers[val.class_details?.id], val.class_details?.id);
      }
    }

    document.getElementsByClassName("search-container")[0].style.display = "none";
    document.getElementsByClassName("pagination")[0].style.cssText = 'display:none !important';
  }

  /****************************************************************/

  /**************function called on search box********************************/
  show3 = async (value) => {
    if (this.state.is_search === true) {
      const val = { "user": value._source, "id": value._source.student_id };

      this.setState({
        open3: true, id: val.user.student_id, user_id: val.user.user_id,
        name: val.user.name === undefined || val.user.name === null ? '' : val.user.name,
        username: val.user.username === undefined || val.user.username === null ? '' : val.user.username,
        email: val.user.email === undefined || val.user.email === null ? '' : val.user.email,
        mobile: val.user.mobile === undefined || val.user.mobile === null ? '' : val.user.mobile,
        image: val.user.profile_picture === undefined || val.user.profile_picture === null
          ? '' : val.user.profile_picture,
        roll_no: val.user.roll_no === undefined || val.user.roll_no === null ? '' : val.user.roll_no,
        imageURL: null, classs: val.user?.class_id === undefined ? ''
          : val.user?.class_id, branch: val.user.center,
        section: val.user?.batch_id === undefined ? '' : val.user?.batch_id,
        dis_code: val.user.roll_no === undefined || val.user.roll_no === null ? '' : val.user.roll_no,
        dis_branch: val.user?.center_label
      });
      if (val.user?.class_id !== undefined && val.user?.class_id != '' && val.user?.class_id != null) {
        this.getSectionList(this.state.class_centers[val.user?.class_id], val.user?.class_id);
      }

      document.getElementsByClassName("search-container")[0].style.display = "none";

    } else {
      const val = value;

      this.setState({
        open3: true, id: val.id, user_id: val.user.id,
        name: val.user.name === undefined || val.user.name === null ? '' : val.user.name,
        username: val.user.username === undefined || val.user.username === null ? '' : val.user.username,
        email: val.user.email === undefined || val.user.email === null ? '' : val.user.email,
        mobile: val.user.mobile === undefined || val.user.mobile === null ? '' : val.user.mobile,
        image: val.user.profile_picture === undefined || val.user.profile_picture === null
          ? '' : val.user.profile_picture,
        roll_no: val.roll_no === undefined || val.roll_no === null ? '' : val.roll_no,
        imageURL: null, classs: val.class_details.id === undefined ? ''
          : val.class_details.id, branch: val.user.center,
        section: val.section_details.id === undefined ? '' : val.section_details.id,
        //filterClasses: this.state.classes.filter(classs => classs.center === val.user.center),
        dis_code: val.roll_no === undefined || val.roll_no === null ? '' : val.roll_no,
        dis_branch: val.user.branch.name
      });
      if (val.class_details?.id !== undefined) {
        this.getSectionList(this.state.class_centers[val.class_details?.id], val.class_details?.id);
      }
    }
  }
  /*******************************************************/

  close2 = () => {
    document.getElementsByClassName("search-container")[0].style.display = "flex";
    document.getElementsByClassName("pagination")[0].style.cssText = 'display:content !important';
    this.setState({ open2: false });
  }

  close3 = () => {
    let { class_batch_mapping } = this.state;

    class_batch_mapping = [];

    var option = {
      "class_id": "",
      "batch_id": ""
    }

    class_batch_mapping.push(option);
    this.setState({ open3: false, class_batch_mapping });
  }

  showd = (val) => this.setState({ opend: true, d_id: val });

  closed = () => this.setState({ opend: false });

  showf = (val) => this.setState({ openf: true });

  closef = () => this.setState({ openf: false });

  bulkInvite = () => this.setState({
    invite: true,
    user_ids: this.state.filterStudents.map(function (obj) { return obj.id })
  });

  bulkResendPassword = () => this.setState({
    resend: true,
    user_ids: this.state.filterStudents.map(function (obj) { return obj.user.id })
  });

  closeInvite = () => this.setState({ invite: false, user_ids: [] });
  closeResendPassword = () => this.setState({ resend: false, user_ids: [] });

  getStudentList = async (center, filterClass, filterSection, page) => {
    const response = await this.props.getStudentList(center, filterClass, filterSection, page);
    if (response !== undefined) {
      this.setState({ students: response.data.data, filterStudents: response.data.data, current_page: response.data?.pagination?.current_page, totalItems: response.data?.pagination?.total_page * 20 })
    }
  }

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if (response !== undefined) {
      this.setState({ branches: response.data, centers: response.data });
    }
  }

  getClassList = async () => {
    const response = await this.props.getClassList2();
    if (response !== undefined) {
      this.setState({
        classes: response.data, class_names: response.data.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ),
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id))
      });
    }
  }

  getSectionList = async (class_center, class_id) => {
    const response = await this.props.getSectionList2(class_center, class_id);
    if (response !== undefined) {

      this.setState({
        sections: response.data, section_names: response.data.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item.label }), {})
      });
    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  filterCenterChange = (e) => {
    if (e.target.value === 'Filter by Center') {
      this.setState({
        filterCenter: '', filterClass: '', filterSection: '',
        filterClassName: '', filterSectionName: ''
      });
      this.getStudentList(this.state.center_id, this.state.filterClass, this.state.filterSection, this.state.current_page);
    }
    else {
      this.setState({
        filterCenter: e.target.value, filterClass: '', filterSection: '',
        filterClassName: '', filterSectionName: '',
        filterClasses: this.state.classes.filter(classs => classs.center === parseInt(e.target.value))
      });
      this.getStudentList(e.target.value, this.state.filterClass, this.state.filterSection, this.state.current_page);
    }
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  rollChange = (e) => {
    this.setState({ roll_no: e.target.value })
  }

  emailChange = (e) => {
    this.setState({ email: e.target.value })
  }
  userNameChange = (e) => {
    this.setState({ username: e.target.value })
  }

  mobileChange = (e) => {
    const mobile = (e.target.validity.valid) ? e.target.value : this.state.mobile;
    this.setState({ mobile })
  }

  passwordChange = (e) => {
    this.setState({ password: e.target.value })
  }

  branchChange = (e) => {
    if (e.target.value === 'Select Branch' || e.target.value === 'Edit Branch') {
      this.setState({ branch: '' })
    }
    else {
      this.setState({ branch: e.target.value })
    }
  }

  classChange = (e) => {

    if (e.target.value === 'Assign Class') {
      this.setState({ classs: '', section: '', class_center: '' })
    }
    else {
      this.setState({
        classs: e.target.value, section: '',
        class_center: this.state.class_centers[e.target.value]
      })

      this.getSectionList(this.state.class_centers[e.target.value], e.target.value)
    }
  }

  handleClearCache = async () => {
    const { center_id } = this.state;
    this.setState({ clearCacheLoading: true });

    try {
      const response = await this.props.getStdClearCache(center_id);
      if (response) {
        this.setState({
          center_id: center_id,
          clearCacheLoading: false 
        });
      
        store.addNotification({
          title: 'Success',
          message: 'Cache cleared successfully',
          type: 'success',
          container: 'top-right',
          dismiss: {
            duration: 3000
          }
        });
      }
    } catch (error) {
      console.error("Error clearing cache:", error);
      this.setState({ clearCacheLoading: false }); 
    
      store.addNotification({
        title: 'Error',
        message: 'Failed to clear cache',
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      });
    }
  };



  classChange1 = (e, index) => {
    let { class_batch_mapping, filterClasses, classListOnChange } = this.state

    if (e.target.value === 'Assign Class') {
      this.setState({ classs: '', section: '', class_center: '' })
    }
    else {

      classListOnChange.push(parseInt(e.target.value));
      this.setState({
        classs: e.target.value, section: '',
        class_center: this.state.class_centers[e.target.value]
      })

      class_batch_mapping[index].class_id = e.target.value;

      this.setState({ class_batch_mapping, filterClasses, classListOnChange })

      this.getSectionList1(this.state.class_centers[e.target.value], e.target.value, index)
    }
  }

  getSectionList1 = async (class_center, class_id, index) => {

    let { sections1 } = this.state;

    const response = await this.props.getSectionList2(class_center, class_id);
    if (response !== undefined) {

      sections1[index] = response.data;

      this.setState({
        sections: response.data, sections1, section_names: response.data.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item.label }), {})
      });
    }
  }


  sectionChange = (e) => {

    if (e.target.value === 'Assign Section') {
      this.setState({ section: '' })
    }
    else {
      this.setState({ section: e.target.value })
    }
  }

  sectionChange1 = (e, index) => {
    let { class_batch_mapping } = this.state

    if (e.target.value === 'Assign Section') {
      this.setState({ section: '' })
    }
    else {
      this.setState({ section: e.target.value })
      class_batch_mapping[index].batch_id = e.target.value;

      this.setState({ class_batch_mapping })
    }
  }

  classChange2 = (e) => {
    if (e.target.value === 'Select Class') {
      this.setState({
        filterClass: '', filterSection: '', filterClassName: '',
        filterSectionName: ''
      });
    }
    else {
      this.setState({
        filterClass: e.target.value, filterSection: '', filterSectionName: '',
        filterClassName: this.state.class_names[e.target.value],
        class_center: this.state.class_centers[e.target.value]
      })
      this.getSectionList(this.state.class_centers[e.target.value], e.target.value)
    }
  }

  sectionChange2 = (e) => {
    if (e.target.value === 'Select Section') {
      this.setState({ filterSection: '', filterSectionName: '' })
    }
    else {
      this.setState({
        filterSection: e.target.value,
        filterSectionName: this.state.section_names[e.target.value]
      });
    }
  }

  filterClassSection = () => {
    if (this.state.filterClass !== '' && this.state.filterSection !== '') {
      /*var students = this.state.students.filter(student => {
        return student?.class_details?.id === parseInt(this.state.filterClass)
          && student?.section_details?.id === parseInt(this.state.filterSection)
      });
      this.setState({ filterStudents: students });*/
      this.getStudentList(this.state.center_id, this.state.filterClass, this.state.filterSection, this.state.current_page);
    }
    this.setState({ openf: false })
  }

  removeFilters = () => {

    this.setState({
      filterClass: '', filterSection: '', filterClassName: '',
      filterSectionName: '', openf: false
    });

    this.getStudentList(this.state.center_id, '', '', this.state.current_page);
  }

  getStudentCenter = (center) => {

    if (center) {
      return this.state.branches.filter(p => p.id == center).map(center => center.name)[0];
    }

  }

  handleChange = (event) => {
    if (event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_image_can_be_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('img-input').value = "";
  }

  newStudent = async () => {
    const { name, email, mobile, password, center_id, roll_no } = this.state;
    if (common.validateEmail(email)) {
      let lastPage = this.state.totalItems / 20;
      localStorage.setItem("curruntPageNumber", lastPage)
      const response = await this.props.createStudent(name, email, mobile === "" ? null : mobile, password, center_id, roll_no);
      if (response != undefined) {
        this.setState({ open: false });
      }
      //await this.props.createStudent(name, email, mobile, password, center_id, roll_no);
      //this.setState({ open: false });
    }
    else {
      store.addNotification({
        title: 'Invalid email',
        message: `${this.props.pagewiseLanguageData.enter_valid_email}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  updateStudent = async () => {
    const { id, name, email, mobile, password, classs, image, section, username } = this.state;
    if (common.validateEmail(email)) {
      await this.props.updateStudent(id, name, email, mobile, password, classs, image, section, username);
      this.setState({ open2: false });
    }
    else {
      store.addNotification({
        title: 'Invalid email',
        message: `${this.props.pagewiseLanguageData.enter_valid_email}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  deleteStudent = async () => {
    const { d_id, center_id } = this.state;
    await this.props.deleteStudent(d_id, center_id);
    this.setState({ opend: false });
  }

  sendInvite = async (val) => {
    const { mail_type } = this.state;
    await this.props.sendInvite(mail_type, [val]);
  }

  resendPassword = async (val) => {
    await this.props.sendInvitePassword([val]);
  }

  sendBulkInvite = async () => {
    const { mail_type, user_ids } = this.state;
    await this.props.sendInvite(mail_type, user_ids);
    this.setState({ user_ids: [], invite: false })
  }

  sendBulkPassword = async () => {

    const { user_ids } = this.state;
    await this.props.sendInvitePassword(user_ids);
    this.setState({ user_ids: [], resend: false })
  }

  changeIds = (val) => {
    if (this.state.user_ids.indexOf(val) !== -1) {
      const index = this.state.user_ids.indexOf(val);
      this.state.user_ids.splice(index, 1);
    }
    else {
      this.state.user_ids.push(val);
    }
  }

  show4 = async (page) => {

    if (this.state.is_search) {
      const response = await this.props.userSearchList(this.state.text, page);
      if (response !== undefined) {
        this.setState({ is_search: true, students: response.data.data, filterStudents: response.data.data })
      }
    } else {
      const response = await this.props.getStudentList(this.state.center_id, this.state.filterClass, this.state.filterSection, page);
      if (response !== undefined) {
        this.setState({ students: response.data.data, filterStudents: response.data.data })
      }
    }

    this.setState({ current_page: page });
  }

  handlePageChange(pageNumber) {
    this.show4(pageNumber);
    this.setState({ current_page: pageNumber });
  }



  // show6 = async (e) => {

  //   clearInterval(this.timer)
  //   //if(!e.target.value) document.getElementsByClassName("tab-content")[0].style.display="none";
  //   this.setState({ text: e.target.value })

  //   let textValue = e.target.value

  //   this.timer = setTimeout(async () => {
  //     if (textValue) {
  //       const response = await this.props.userSearchList(textValue, 1);
  //       if (response !== undefined) {
  //         this.setState({ is_search: true, students: response.data.data, filterStudents: response.data.data, current_page: response.data?.pagination?.current_page, totalItems: response.data?.pagination?.total_page * 20 })
  //       }
  //     } else {
  //       const response = await this.props.getStudentList(this.state.center_id, this.state.filterClass, this.state.filterSection, this.state.current_page);
  //       if (response !== undefined) {
  //         this.setState({ is_search: false, students: response.data.data, filterStudents: response.data.data, current_page: response.data?.pagination?.current_page, totalItems: response.data?.pagination?.total_page * 20 })
  //       }
  //     }
  //   }, 1000);

  // }

  // show6 = debounce(async (e) => {
  //   clearInterval(this.timer);
  //   this.setState({ text: e.target.value }, async () => {
  //     let textValue = this.state.text;
  //     if (textValue) {
  //       const response = await this.props.userSearchList(textValue, 1);
  //       if (response) {
  //         this.setState({
  //           is_search: true,
  //           students: response.data.data,
  //           filterStudents: response.data.data,
  //           current_page: response.data?.pagination?.current_page,
  //           totalItems: response.data?.pagination?.total_page * 20
  //         });
  //       }
  //     } else {
  //       const response = await this.props.getStudentList(
  //         this.state.center_id,
  //         this.state.filterClass,
  //         this.state.filterSection,
  //         this.state.current_page
  //       );
  //       if (response) {
  //         this.setState({
  //           is_search: false,
  //           students: response.data.data,
  //           filterStudents: response.data.data,
  //           current_page: response.data?.pagination?.current_page,
  //           totalItems: response.data?.pagination?.total_page * 20
  //         });
  //       }
  //     }
  //   });
  // }, 1000);

  // In constructor, define debounced method


  // Add new handler for immediate state update
  handleSearchChange = (e) => {
    const textValue = e.target.value;
    this.setState({ text: textValue }, () => {
      this.debouncedSearch(textValue);
    });
  };

  // Modified search handler
  handleSearch = async (textValue) => {
    if (textValue) {
      const response = await this.props.userSearchList(textValue, 1);
      if (response) {
        this.setState({
          is_search: true,
          students: response.data.data,
          filterStudents: response.data.data,
          current_page: 1,
          totalItems: response.data?.pagination?.total_page * 20
        });
      }
    } else {
      const response = await this.props.getStudentList(
        this.state.center_id,
        this.state.filterClass,
        this.state.filterSection,
        1
      );
      if (response) {
        this.setState({
          is_search: false,
          students: response.data.data,
          filterStudents: response.data.data,
          current_page: 1,
          totalItems: response.data?.pagination?.total_page * 20
        });
      }
    }
  };



  hideChange = () => this.setState({ hide: !this.state.hide })
  addOption = (val) => {
    let { class_batch_mapping } = this.state;

    var option = {
      "class_id": "",
      "batch_id": ""
    }
    class_batch_mapping.push(option);

    this.setState({ class_batch_mapping })
  }

  classbatchmapping = async () => {

    const { class_batch_mapping, center_id, user_id } = this.state

    const response = await this.props.classbatchmapping(center_id, user_id, class_batch_mapping);

  }


  bulkUserUpload = () => {
    this.setState({ isDialogVisible: true });
  }

  bulkPasswordUpload = () => {
    this.setState({ isBulkPasswordDialogVisible: true });
  }

  bulkUserClose = () => {
    this.setState({ isDialogVisible: false });
  }

  bulkPasswordClose = () => {

    this.setState({ isBulkPasswordDialogVisible: false });
    console.log('pop is close ', this.state.isBulkPasswordDialogVisible)
  }



  bulkUserFileClick = (event) => {
    document.getElementById("bulkUserCreate").click();

  }

  bulkPasswordFileClick = (event) => {
    document.getElementById("bulkPasswordUpdate").click();

  }
  bulkUserFileChange = async (event) => {
    console.log(event.target.files[0].type.split('/')[0]);
    let getFile = event.target.files[0];
    let getFileURL = URL.createObjectURL(getFile);
    console.log(getFile);
    console.log(getFileURL);
    const response = await this.props.bulkUserCreate(getFile);
    if (response !== undefined) {
      console.log(response.data);
      this.setState({ bulkUserJobData: response.data.job_id });
      this.setState({ bulkUserTableShow: true });
      this.intervalFunction(response.data.job_id);


    }
  }

  intervalFunction = async (jobid) => {
    return await new Promise(resolve => {
      const interval = setInterval(() => {
        console.log('Logs every minute');
        this.bulkUserFileCheckStatus(jobid);
        if (this.state.bulkUserJobStatus) {
          console.log(this.state.bulkUserJobStatus);
          clearInterval(interval);
        }
      }, 10000);
    })
  }

  bulkPasswordFileChange = async (event) => {
    console.log(event.target.files[0].type.split('/')[0]);
    let getFile = event.target.files[0];
    let getFileURL = URL.createObjectURL(getFile);
    console.log(getFile);
    console.log(getFileURL);
    const response = await this.props.bulkPasswordUpdate(getFile);
    if (response !== undefined) {
      console.log(response.data);
      this.setState({ bulkPasswordUpdateJobData: response.data.job_id });
      this.setState({ bulkPasswordUpdateTableShow: true });
      this.intervalFunction2(response.data.job_id);


    }
  }

  intervalFunction2 = async (jobid) => {
    return await new Promise(resolve => {
      const interval = setInterval(() => {
        console.log('Logs every minute');
        this.bulkPasswordUpdateFileCheckStatus(jobid);
        if (this.state.bulkPasswordUpdateJobStatus) {
          console.log(this.state.bulkPasswordUpdateJobStatus);
          clearInterval(interval);
        }
      }, 10000);
    })
  }


  bulkPasswordUpdateFileCheckStatus = async (jobid) => {
    if (!this.state.bulkPasswordUpdateJobStatus) {
      const response = await this.props.bulkUserJobID(jobid);
      if (response !== undefined) {
        console.log('response password Update');
        console.log(response);
        this.setState({ bulkPasswordUpdateJobStatus: true });
      } else {
        console.log("response?.status password update")
        this.setState({ bulkPasswordUpdateJobCounter: this.state.bulkPasswordUpdateJobCounter + 1 });
        if (this.state.bulkPasswordUpdateJobCounter == 5) {
          this.setState({ bulkPasswordUpdateJobStatus: true });
          this.setState({ bulkPasswordJobStatusError: true });
          setTimeout(window.location.reload.bind(window.location), 1000);
        }
      }

    }
  }


  bulkUserFileCheckStatus = async (jobid) => {
    if (!this.state.bulkUserJobStatus) {
      const response = await this.props.bulkUserJobID(jobid);
      if (response !== undefined) {
        console.log('response');
        console.log(response);
        this.setState({ bulkUserJobStatus: true });
      } else {
        console.log("response?.status")
        this.setState({ bulkUserJobCounter: this.state.bulkUserJobCounter + 1 });
        if (this.state.bulkUserJobCounter == 5) {
          this.setState({ bulkUserJobStatus: true });
          this.setState({ bulkUserJobStatusError: true });
          setTimeout(window.location.reload.bind(window.location), 1000);
        }
      }

    }
  }

  UpdatePassword = (id) => {
    let userId = id;
    this.setState({ isPasswordDialogVisible: true });
    this.setState({ saveUserId: userId });

  }

  UpdatePasswordClose = () => {
    this.setState({ isPasswordDialogVisible: false });
  }

  updatePasswordClick = async () => {
    console.log(this.state.savePasswordValue);
    console.log(this.state.saveUserId);
    const response = await this.props.userResetPassword(this.state.saveUserId, this.state.savePasswordValue, localStorage.getItem('lang'), localStorage.getItem('user_id'));
    if (response !== undefined) {
      console.log('response');
      console.log(response);
    }
  }

  updatePasswordChange = (e) => {
    this.setState({ savePasswordValue: e.target.value });
  }


  activeInactiveFunction = (userid, is_active) => {
    this.setState({ saveUserId: userid });
    this.setState({ saveUserDefaultStatus: is_active });
    this.setState({ isActiveInactiveDialogVisible: true });
  }

  ConfirmDialogClose = (e) => {
    this.setState({ isActiveInactiveDialogVisible: false });
  }
  ConfirmDialogYes = async () => {
    console.log("is_search")
    console.log(this.state.is_search)
    const response = await this.props.studentActiveInactive(this.state.saveUserId, this.state.saveUserDefaultStatus ? 0 : 1);
    if (response !== undefined) {
      console.log('response');
      console.log(response);
    }

  }


  activeInactiveFilterChange = async (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ saveActiveInavtiveValue: e.target.value });
      const response = await this.props.getStudentListActiveInactive(this.state.center_id, this.state.filterClass, this.state.filterSection, 1, e.target.value);
      if (response !== undefined) {
        console.log('response active inactive student list');
        console.log(response);
        this.setState({ students: response.data.data, filterStudents: response.data.data, current_page: response.data?.pagination?.current_page, totalItems: response.data?.pagination?.total_page * 20 })
      }


    }
  }
  handlePasswordChange = (e) => {
    this.setState({ setNewPassword: e.target.value }); // update the Password state
  };


  // saveNewPassword = async () => {

  //   const response = await this.props.studentPasswordUpdate(
  //     this.state.filterClass,
  //     this.state.filterSection,
  //     this.state.setNewPassword
  //   );


  //   if (response !== undefined) {
  //     console.log('response active inactive student list');
  //     console.log(response);

  //     if (response.success) {
  //       alert('Password updated successfully!'); // Show success message

  //       // Close the popup (assuming you have a method like this.closePopup())
  //       // this.setState({ open: false });
  //       // this.setState({ isBulkPasswordDialogVisible: false });
  //       // this.setState({ isBulkPasswordDialogVisible: false });
  //       this.bulkPasswordClose();
  //     }
  //   }
  // }
  saveNewPassword = async () => {
    try {
      // Call the studentPasswordUpdate function with the required parameters
      const response = await this.props.studentPasswordUpdate(
        this.state.filterClass,
        this.state.filterSection,
        this.state.setNewPassword
      );

      if (response !== undefined) {
        console.log('Response from student password update:', response);
        alert('Password updated successfully!');


        this.setState({ isBulkPasswordDialogVisible: false });


      } else {

        alert('No response from the server.Please check your connection.');
      }
    } catch (error) {

      console.error('Error updating password:', error);

    }
  };





  render() {
    const { classListOnChange } = this.state;
    const isEnabled = this.state.name.length > 0 && this.state.email.length > 0 &&
      // ((this.state.mobile + '').length === 10 || (this.state.mobile + '').length === 0) && this.state.password.length > 0 &&
      this.state.mobile.length > 0 && this.state.password.length > 0 &&
      this.state.roll_no.length > 0 && this.state.branch !== '';
    // const isEnabled2 = this.state.name.length > 0 && this.state.email.length > 0 &&
    // ((this.state.mobile + '').length === 10 || (this.state.mobile + '').length === 0)

    const isEnabled2 = (this.state.name.length > 0 && this.state.email.length > 0 &&
      this.state.mobile.length > 0) || this.state.mobile != ''
    //(this.state.mobile +'').length === 10;
    // && this.state.roll_no.length > 0;
    const isEnabled3 = this.state.user_ids.length > 0;
    const isEnabledf = this.state.filterClass !== '' && this.state.filterSection !== '';
    if (this.props.loading || this.state.clearCacheLoading) {

      

      return (
        <div className="admin-div">
          <Header placeholder="Search students" />
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.students}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"} />
              <Shimmer className={"list-item"} />
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"} />
              <Shimmer className={"grid-item"} />
            </div>}
          </div>
        </div>
      )
    }

    // if(loading){
    //   <Loader />
    // }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.students}</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder={this.props.pagewiseLanguageData.search_students} />

        {/* User Active Inactive Confirmation Dialog Start*/}
        <div className={this.state.isActiveInactiveDialogVisible ? 'confirmDialogWrapper open' : 'confirmDialogWrapper'}>
          <div className='confirmDialogContainer'>
            <span className="close" onClick={this.ConfirmDialogClose}>✕</span>
            <div className='popup-header'>{this.props.pagewiseLanguageData.confirmation}</div>
            <div className='formWrapper'>
              {this.props.pagewiseLanguageData.are_you_sure_you_want_to} {!this.state.saveUserDefaultStatus ? this.props.pagewiseLanguageData.activate : this.props.pagewiseLanguageData.deactivate} {this.props.pagewiseLanguageData.this_user}
            </div>
            <div className='formActions'>
              <button onClick={this.ConfirmDialogClose}>{this.props.pagewiseLanguageData.cancel}</button>
              <button onClick={this.ConfirmDialogYes}>{this.props.pagewiseLanguageData.yes}</button>
            </div>
          </div>
        </div>
        {/* User Active Inactive Confirmation Dialog Ends*/}

        {/* Password update Dialog Start*/}
        <div className={this.state.isPasswordDialogVisible ? 'updatePasswordDialog open' : 'updatePasswordDialog'}>
          <div className='updatePasswordContainer'>
            <span className="close" onClick={this.UpdatePasswordClose}>✕</span>
            <div className='popup-header'>{this.props.pagewiseLanguageData.update_password}</div>
            <div className='formWrapper'>
              {/* <input type="password" onChange={this.updatePasswordChange} placeholder={this.props.pagewiseLanguageData.enter_your_password} type={this.state.hide ? "password" : "text"} id="passwordInput" /> */}
              <input
                type={this.state.hide ? "password" : "text"}
                onChange={this.updatePasswordChange}
                placeholder={this.props.pagewiseLanguageData.enter_your_password}
                id="passwordInput"
              />
              <div className="togglePassword" onClick={this.hideChange}>
                {this.state.hide === true ? <img src={Hide} alt="see" />
                  : <img src={See} alt="hide" />}
              </div>
            </div>
            <div className='formActions'>
              <button onClick={this.updatePasswordClick}>{this.props.pagewiseLanguageData.update_password}</button>
            </div>
          </div>
        </div>
        {/*Password update Dialog End*/}

        {/* Bulk User create Dialog Start*/}
        <div className={this.state.isDialogVisible ? 'bulkUserUploadDialog open' : 'bulkUserUploadDialog'}>
          <div className='bulkUserUploadContainer'>
            <span className="close" onClick={this.bulkUserClose}>✕</span>
            <div className='popup-header'>{this.props.pagewiseLanguageData.bulk_user_create}</div>
            <div className='actions'>
              <a href={sampleFile} download><img src={DownloadArrow} /><i>{this.props.pagewiseLanguageData.download_sample_file}</i></a>
              <input type="file" onChange={this.bulkUserFileChange} id='bulkUserCreate' accept=".csv" style={{ display: 'none' }} />
              <span onClick={this.bulkUserFileClick} ><img src={DownloadArrow} /><i>{this.props.pagewiseLanguageData.upload_file}</i></span>
            </div>
            <div className={this.state.bulkUserTableShow ? 'tableWrapper display' : 'tableWrapper'}>
              <table>
                <thead>
                  <tr>
                    <th>{this.props.pagewiseLanguageData.job_id}</th>
                    <th>{this.props.pagewiseLanguageData.status}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{this.state.bulkUserJobData}</td>
                    <td>{this.state.bulkUserJobStatus ? this.props.pagewiseLanguageData.success : this.props.pagewiseLanguageData.processing}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {this.state.bulkUserJobStatusError &&
              <div className='somthingWenWrongMsg'>{this.props.pagewiseLanguageData.invalid_csv_Format}</div>
            }
          </div>
        </div>
        {/* Bulk User create Dialog Ends*/}


        {/* Bulk Password Update Dialog Start*/}
        <div className={this.state.isBulkPasswordDialogVisible ? 'bulkUserUploadDialog open' : 'bulkUserUploadDialog'}>
          <div className='bulkUserUploadContainer'>
            <span className="close" onClick={this.bulkPasswordClose}>✕</span>
            <div className='popup-header'>{this.props.pagewiseLanguageData.bulk_password_upload}</div>
            <div className='actions'>
              <a href={samplePasswordUpdateFile} download><img src={DownloadArrow} /><i>{this.props.pagewiseLanguageData.download_sample_file}</i></a>
              <input type="file" onChange={this.bulkPasswordFileChange} id='bulkPasswordUpdate' accept=".csv" style={{ display: 'none' }} />
              <span onClick={this.bulkPasswordFileClick} ><img src={DownloadArrow} /><i>{this.props.pagewiseLanguageData.upload_file}</i></span>
            </div>
            <div style={{ width: '100%', height: '1px', backgroundColor: '#000' }}></div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', borderRadius: '10px', width: '400px', margin: '0 auto' }}>


              <div style={{ width: '100%', marginBottom: '20px' }}>
                <label style={{ fontWeight: 'bold', marginBottom: '10px', display: 'block' }}>Select Class</label>
                <select
                  value={this.state.filterClass}
                  onChange={this.classChange2}
                  style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '20px' }}
                >
                  <option value={''}>
                    {this.props.pagewiseLanguageData.select_class}
                  </option>
                  {this.state.filterClasses.map((classs, index) =>
                    <option value={classs.id} key={index}>
                      {classs.label}
                    </option>
                  )}
                </select>
              </div>

              <div style={{ width: '100%', marginBottom: '20px' }}>
                <label style={{ fontWeight: 'bold', marginBottom: '10px', display: 'block' }}>Select Section</label>
                <select
                  value={this.state.filterSection}
                  onChange={this.sectionChange2}
                  style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '20px' }}
                >
                  <option value={''}>
                    {this.props.pagewiseLanguageData.select_section}
                  </option>
                  {this.state.sections.map((section, index) =>
                    <option value={section.id} key={index}>
                      {section.label}
                    </option>
                  )}
                </select>
              </div>

              <div style={{ width: '100%', marginBottom: '20px' }}>
                <label style={{ fontWeight: 'bold', marginBottom: '10px', display: 'block' }}>New Password</label>
                <input
                  type="text"
                  placeholder="Enter new password"
                  value={this.state.setNewPassword}
                  onChange={this.handlePasswordChange}
                  style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                />
              </div>

              <button
                style={{
                  width: '100%',
                  backgroundColor: '#000',
                  color: '#fff',
                  padding: '10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  marginBottom: '20px'
                }}
                onClick={this.saveNewPassword}
              >
                Update Password
              </button>


            </div>
            <div className={this.state.bulkPasswordUpdateTableShow ? 'tableWrapper display' : 'tableWrapper'}>
              <table>
                <thead>
                  <tr>
                    <th>{this.props.pagewiseLanguageData.job_id}</th>
                    <th>{this.props.pagewiseLanguageData.status}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{this.state.bulkPasswordUpdateJobData}</td>
                    <td>{this.state.bulkPasswordUpdateJobStatus ? this.props.pagewiseLanguageData.success : this.props.pagewiseLanguageData.processing}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {this.state.bulkPasswordUpdateJobStatusError &&
              <div className='somthingWenWrongMsg'>{this.props.pagewiseLanguageData.invalid_csv_Format}</div>
            }
          </div>

        </div>

        {/* Bulk Password Update Dialog Ends*/}


        <Popup open={this.state.open} closeOnDocumentClick={false} onClose={this.close}
          className="popup-content">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_new_student}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.student_name} type="text" onChange={this.nameChange} />
              <input placeholder={this.props.pagewiseLanguageData.roll_no} type="text" onChange={this.rollChange} />
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.email_id} type="email" onChange={this.emailChange} />
              {/* <input placeholder="Mobile No." type="text" onInput={this.mobileChange}
                maxLength={10} value={this.state.mobile} pattern="[0-9]*"/> */}
              <input placeholder={this.props.pagewiseLanguageData.mobile_no} type="text" onInput={this.mobileChange}
                value={this.state.mobile} />
            </div>
            <div className="popup-inputs">
              <select value={this.state.branch} onChange={this.branchChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_branch}
                </option>
                {this.state.branches.map((branch, index) =>
                  <option value={branch.id} key={index}>
                    {branch.name}
                  </option>
                )}
              </select>
              <input placeholder={this.props.pagewiseLanguageData.set_password} type="password" onChange={this.passwordChange} />
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newStudent}>{this.props.pagewiseLanguageData.add_new}</button>
          </div>
        </Popup>
        <Popup open={this.state.openf} closeOnDocumentClick onClose={this.closef}
          className="popup-content5">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.filter_with_class_and_section}
          </div>
          <span className="close" onClick={this.closef}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <select value={this.state.filterClass} onChange={this.classChange2}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_class}
                </option>
                {this.state.filterClasses.map((classs, index) =>
                  <option value={classs.id} key={index}>
                    {classs.label}
                  </option>
                )}
              </select>
              <select value={this.state.filterSection} onChange={this.sectionChange2}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_section}
                </option>
                {this.state.sections.map((section, index) =>
                  <option value={section.id} key={index}>
                    {section.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <div className="filter-buttons">
            <span style={this.state.filterClass === '' && this.state.filterSection === ''
              ? { pointerEvents: 'none' } : {}} onClick={this.removeFilters}>{this.props.pagewiseLanguageData.revome_filters}</span>
            <button onClick={this.filterClassSection} disabled={!isEnabledf}>
              {this.props.pagewiseLanguageData.save_filter}
            </button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete" />
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteStudent}>
              {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part paginationshow">
          <div className="sub-head">
            <div className="heading-part1-ques">
              {!this.state.open3 && <div className="search-head">
                {/*<img src={Search} alt="search"/>
                <input placeholder={this.props.placeholder}/>*/}
                <div className="search-wrapper-admin">
                  <div className="search-container">
                    {/* <input type="search" placeholder={this.props.pagewiseLanguageData.search_students} onChange={this.show6} onFocus={this.handleUsernameFocus} value={this.state.text || ''} /> */}
                    <input
                      type="search"
                      placeholder={this.props.pagewiseLanguageData.search_students}
                      onChange={this.handleSearchChange}
                      value={this.state.text || ''}
                    />
                  </div>
                </div>
              </div>}
            </div>
            <div className="heading-part2">
              {this.state.open2 === false ? <>
                {this.state.invite ? <>
                  <button id="button-reverse" onClick={this.closeInvite}>
                    {this.props.pagewiseLanguageData.cancel}
                  </button>
                  <button disabled={!isEnabled3} onClick={this.sendBulkInvite}>
                    {this.props.pagewiseLanguageData.send}
                  </button>
                </> : <>  {this.state.resend ? <>
                  <button id="button-reverse" onClick={this.closeResendPassword}>
                    {this.props.pagewiseLanguageData.cancel}
                  </button>
                  <button disabled={!isEnabled3} onClick={this.sendBulkPassword}>
                    {this.props.pagewiseLanguageData.send}
                  </button>
                </> : <>
                  <span onClick={this.handleClearCache}>
                    <img src={Refresh} alt="Refresh" color='white' />Clear Cache
                  </span>
                  <span onClick={this.bulkInvite}>
                    <img src={Invitew} alt="img" />{this.props.pagewiseLanguageData.sent_invite}
                  </span>
                  <span onClick={this.bulkResendPassword}>
                    <img src={Password} alt="img" />{this.props.pagewiseLanguageData.resend_password}
                  </span>
                  {/*this.state.is_primary && <span onClick={this.show}>
                    <img src={Add} alt="img"/>Add New
                  </span>*/}
                  <span onClick={this.show}>
                    <img src={Add} alt="img" />{this.props.pagewiseLanguageData.add_new}
                  </span>
                  {!this.state.list ? <span onClick={() => this.changeView('false')}>
                    <img src={List} alt="img" />{this.props.pagewiseLanguageData.list_view}
                  </span> : <span onClick={() => this.changeView('true')}>
                    <img src={Grid} alt="img" />{this.props.pagewiseLanguageData.grid_view}
                  </span>}
                </>}
                </>}</> : null}
            </div>
          </div>
          {!this.state.is_search && <div className="sub-filter spaceSet">
            <div className='filters'>
              {!this.state.open2 && <select defaultValue={parseInt(this.state.filterCenter)}
                onChange={this.filterCenterChange}>
                {this.state.centers.map(center =>
                  <option key={center.id} value={center.id}>{center.name}</option>
                )}
              </select>}
              {!this.state.open2 && <>
                {this.state.filterClass !== '' && this.state.filterSection !== ''
                  ? <div className="sub-filter-x" onClick={this.showf}>
                    {this.state.filterClassName} | {this.state.filterSectionName}
                  </div> : <div className="sub-filter-x" onClick={this.showf}>
                    {this.props.pagewiseLanguageData.filter_class_and_section}
                  </div>}
              </>}

              <select className='activeInactiveSelect' onChange={this.activeInactiveFilterChange}>
                <option value="">Filter by Active / Inactive</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div className='bulkUpload'>
              <span onClick={this.bulkUserUpload}>{this.props.pagewiseLanguageData.bulk_user_create}</span>
            </div>
            <div className='bulkUpload marginLeft15'>
              <span onClick={this.bulkPasswordUpload}>{this.props.pagewiseLanguageData.bulk_password_update}</span>
            </div>
          </div>}
          {this.state.filterStudents.length === 0 ? <Empty /> : <>
            {this.state.open2 ? <div className="edit-div">
              <div className="edit-div-1">
                {this.state.image === null || this.state.image === ''
                  ? <div className="edit-dp" onClick={() => this.fileInput.click()}>
                    <img id="img-holder" src={ImgHolder} alt="DP" />
                  </div> : <div className="edit-dp" style={this.state.imageURL === null
                    ? { backgroundImage: "url(" + common.addFileUrl(this.state.image) + ")" }
                    : { backgroundImage: "url(" + this.state.imageURL + ")" }}>
                    <span onClick={this.removeImage}>&#x2715;</span>
                  </div>}
                <input style={{ display: 'none' }} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput} />
                <div className="edit-sub-div-1">
                  <input placeholder={this.props.pagewiseLanguageData.student_name} title={this.props.pagewiseLanguageData.student_name} value={this.state.name} type="text"
                    onChange={this.nameChange} />
                  <input placeholder={this.props.pagewiseLanguageData.email_id} title={this.props.pagewiseLanguageData.email_id} value={this.state.email} type="email"
                    onChange={this.emailChange} />
                  <input placeholder={this.props.pagewiseLanguageData.user_name} title={this.props.pagewiseLanguageData.user_name} value={this.state.username} type="text" onChange={this.userNameChange} />
                </div>
              </div>
              <div className="edit-div-2">
                {/* <input placeholder="Mobile No." type="text" onInput={this.mobileChange}
                  maxLength={10} value={this.state.mobile} title='Mobile No' pattern="[0-9]*"/> */}
                <input placeholder="Mobile No." type="text" onInput={this.mobileChange}
                  value={this.state.mobile} title='Mobile No' />
                <div className='passwordBlock'>
                  <input placeholder="Password" type={this.state.hide ? "password" : "text"} onChange={this.passwordChange} />
                  <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={Hide} alt="see" />
                      : <img src={See} alt="hide" />}
                  </div>
                </div>
              </div>
              <div className="edit-div-2">
                <div>
                  {this.state.dis_branch}
                </div>
                <div>
                  {this.state.dis_code}
                </div>
              </div>
              {/* <div className="edit-div-4">
                <select value={this.state.branch} onChange={this.branchChange}>
                  <option value={null}>
                    Edit Branch
                  </option>
                  {this.state.branches.map((branch, index) =>
                    <option value={branch.id} key={index}>
                      {branch.name}
                    </option>
                  )}
                </select>
              </div> */}
              <div className="edit-div-3">
                <select value={this.state.classs} onChange={this.classChange}>
                  <option value={''}>
                    {this.props.pagewiseLanguageData.assign_class}
                  </option>
                  {this.state.filterClasses.map((classs, index) =>
                    <option value={classs.id} key={index}>
                      {classs.label}
                    </option>
                  )}
                </select>
                <select value={this.state.section} onChange={this.sectionChange}>
                  <option value={''}>
                    {this.props.pagewiseLanguageData.assign_section}
                  </option>
                  {this.state.sections.map((section, index) =>
                    <option value={section.id} key={index}>
                      {section.label}
                    </option>
                  )}
                </select>
              </div>
              <div className="edit-buttons">
                <button id="button-reverse" onClick={this.close2}>
                  {this.props.pagewiseLanguageData.cancel}
                </button>
                <button disabled={!isEnabled2} onClick={this.updateStudent}>
                  {this.props.pagewiseLanguageData.save}
                </button>
              </div>
            </div> : <>{this.state.open3 ? <div className="edit-div">
              <div className="edit-div-1">
                {this.state.image === null || this.state.image === ''
                  ? <div className="edit-dp" onClick={() => this.fileInput.click()}>
                    <img id="img-holder" src={ImgHolder} alt="DP" />
                  </div> : <div className="edit-dp" style={this.state.imageURL === null
                    ? { backgroundImage: "url(" + common.addFileUrl(this.state.image) + ")" }
                    : { backgroundImage: "url(" + this.state.imageURL + ")" }}>
                    <span onClick={this.removeImage}>&#x2715;</span>
                  </div>}
                <input style={{ display: 'none' }} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput} />
                <div className="edit-sub-div-1">
                  <input placeholder={this.props.pagewiseLanguageData.student_name} value={this.state.name} type="text"
                    onChange={this.nameChange} disabled="disabled" />
                  <input placeholder={this.props.pagewiseLanguageData.email_id} value={this.state.email} type="email"
                    onChange={this.emailChange} disabled="disabled" />
                </div>
              </div>
              <div className="edit-div-2">
                {/* <input placeholder="Mobile No." type="text" onInput={this.mobileChange}
                  maxLength={10} value={this.state.mobile} pattern="[0-9]*" disabled="disabled"/> */}
                <input placeholder={this.props.pagewiseLanguageData.mobile_no} type="text" value={this.state.mobile} onInput={this.mobileChange} />
                <input placeholder={this.props.pagewiseLanguageData.password} type="password" onChange={this.passwordChange} disabled="disabled" />
              </div>
              <div className="edit-div-2">
                <div>
                  {this.state.dis_branch}
                </div>
                <div>
                  {this.state.dis_code}
                </div>
              </div>
              <div>
                {this.state.class_batch_mapping && this.state.class_batch_mapping?.map((val, vindex) =>
                  <div className="edit-div-3">
                    <select value={this.state.class_batch_mapping[vindex]?.class_id} onChange={(e) => this.classChange1(e, vindex)}>
                      <option value={''}>
                        {this.props.pagewiseLanguageData.assign_class}
                      </option>
                      {this.state.filterClasses.map((classs, index) =>
                        <option value={classs.id} key={index} disabled={classListOnChange.indexOf(classs.id) !== -1 ? "disabled" : ""}>
                          {classs.label}
                        </option>
                      )}
                    </select>
                    <select value={this.state.class_batch_mapping[vindex]?.batch_id} onChange={(e) => this.sectionChange1(e, vindex)}>
                      <option value=''>
                        {this.props.pagewiseLanguageData.assign_section}
                      </option>
                      {this.state.sections1 && this.state.sections1[vindex]?.map((section, index) =>
                        <option value={section.id} key={index}>
                          {section.label}
                        </option>
                      )}
                    </select>
                  </div>

                )}
                <div className="add-more"><button onClick={() => this.addOption()}>{this.props.pagewiseLanguageData.map_more}</button></div>
              </div>
              <div className="edit-buttons">
                <button id="button-reverse" onClick={this.close3}>
                  {this.props.pagewiseLanguageData.cancel}
                </button>
                <button disabled={!isEnabled2} onClick={this.classbatchmapping}>
                  {this.props.pagewiseLanguageData.save}
                </button>
              </div>
            </div> : <>  {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
                {this.props.pagewiseLanguageData.name}
              </div>
              <div className="list-section">
                {this.props.pagewiseLanguageData.class}
              </div>
              <div className="list-class">
                {this.props.pagewiseLanguageData.section}
              </div>
              <div className="list-subject">
                {this.props.pagewiseLanguageData.roll_no}
              </div>
              <div className="list-faculty">
                {this.props.pagewiseLanguageData.center}
              </div>
              <div className="list-action">
                {this.props.pagewiseLanguageData.actions}
              </div>
            </div> : null}
              {this.state.list ? <div className="the-list the-assessment-list">
                {this.state.filterStudents.map((student, index) =>
                  <div className="list-item" key={student.id}>

                    <div className="title-item3">
                      {this.state.invite && <input type="checkbox" onClick={() => this.changeIds(student.id)}
                        defaultChecked={this.state.user_ids.indexOf(student.id) !== -1 ? true : false} />}
                      {this.state.resend && <input type="checkbox" onClick={() => this.changeIds(student.user.id)}
                        defaultChecked={this.state.user_ids.indexOf(student.user.id) !== -1 ? true : false} />}
                      {!student?.user?.profile_picture || student?.user?.profile_picture === null || student?.user?.profile_picture === ''
                        ? <img src={Profile} alt="dp" /> : <div className="dp_div"
                          style={{ backgroundImage: "url(" + common.addFileUrl(student?.user?.profile_picture) + ")" }}>
                        </div>}

                      <div>
                        {student.user?.name ? student.user?.name : student._source?.name}
                      </div>
                    </div>
                    <div className="section-item">
                      {student.class_details?.name ? student.class_details?.name : student._source?.class_label}
                    </div>
                    <div className="class-item">
                      {student.section_details?.name ? student.section_details?.name : student._source?.batch_label}
                    </div>
                    <div className="subject-item">
                      {student?.roll_no ? student?.roll_no : student._source?.roll_no}
                    </div>
                    <div className="faculty-item">
                      {student.user?.branch.name ? student.user?.branch.name : this.getStudentCenter(student._source?.center)}
                    </div>
                    <div className="action-item">
                      <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(student)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit} />
                      </span>
                      <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(student?.id ? student?.id : student?._source.student_id)}>
                        <img src={Delete} alt={this.props.pagewiseLanguageData.delete} />
                      </span>
                      <div className="invite-class">
                        <div title={this.props.pagewiseLanguageData.send} id="send-invite"
                          onClick={() => this.sendInvite(student?.id ? student?.id : student?._source.student_id)}>
                          <img src={Invite} alt={this.props.pagewiseLanguageData.send} />{this.props.pagewiseLanguageData.send}
                        </div>
                        <div title={this.props.pagewiseLanguageData.resend} id="send-invite"
                          onClick={() => this.resendPassword(student.user?.id ? student.user?.id : student?._source.student_id)}>
                          <img src={Resend} alt={this.props.pagewiseLanguageData.resend} />{this.props.pagewiseLanguageData.resend}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div> : <div className="the-grid">
                {this.state.filterStudents.map((student, index) =>
                  <div className="grid-item" key={index}>
                    <div className="gridActions">
                      <span title="Map" onClick={() => this.show3(student)}>
                        <img src={Mapped} alt="edit" /><i>{this.props.pagewiseLanguageData.map}</i>
                      </span>
                      <span title="Edit" onClick={() => this.show2(student)}>
                        <img src={Edit} alt="edit" /><i>{this.props.pagewiseLanguageData.edit}</i>
                      </span>
                      <span title="Delete" onClick={() => this.showd(student?.id ? student?.id : student?._source.student_id)}>
                        <img src={Delete} alt="delete" /><i>{this.props.pagewiseLanguageData.delete}</i>
                      </span>
                      <div className="invite-class">
                        <div title={this.props.pagewiseLanguageData.send} id="send-invite"
                          onClick={() => this.sendInvite(student?.id ? student?.id : student?._source.student_id)}>
                          <img src={Invite} alt={this.props.pagewiseLanguageData.send} /><i>{this.props.pagewiseLanguageData.send}</i>
                        </div>
                        <div title={this.props.pagewiseLanguageData.resend} id="send-invite"
                          onClick={() => this.resendPassword(student.user?.id ? student.user?.id : student?._source.student_id)}>
                          <img src={Resend} alt={this.props.pagewiseLanguageData.resend} /><i>{this.props.pagewiseLanguageData.resend}</i>
                        </div>


                        <div title={this.props.pagewiseLanguageData.password} id="send-invite" onClick={() => this.UpdatePassword(student?.user?.id ? student?.user?.id : student?._source.user_id)}>

                          <img src={Lock} alt={this.props.pagewiseLanguageData.password} /><i>{this.props.pagewiseLanguageData.password}</i>
                        </div>



                      </div>
                    </div>
                    <div className="grid-up">
                      <div className='userActionInfo'>
                        <div className='viewAttendance'>
                          <Link to={{
                            pathname: `/admin/attendance-list`,
                            state: { user_id: student.user?.id ? student.user?.id : student?._source.user_id, profileName: student.user?.name ? student.user?.name : student._source?.name, openCourseDashboard: true }
                          }}>
                            Course Completion
                          </Link>
                          <Link to={{
                            pathname: `/admin/attendance-list`,
                            state: { user_id: student.user?.id ? student.user?.id : student?._source.user_id, profileName: student.user?.name ? student.user?.name : student._source?.name }
                          }}>
                            View Attendance
                          </Link>
                        </div>
                        <div className='userActiveInactive'>
                          <div onClick={() => this.activeInactiveFunction(student.user?.id ? student.user?.id : student?._source.user_id, student.user?.is_active ? student.user?.is_active : student._source?.is_active)} >
                            {/* <input type="checkbox" checked = {student.user?.is_active ? true : false}/> */}
                            <input type="checkbox" checked={student?.user?.is_active ? true : student?._source?.is_active ? true : false} />
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <Link className="userProfileLink" to={{
                        pathname: `/admin/user-profile`,
                        search: `?user_id=${student.user?.id ? student.user?.id : student?._source.user_id}`,
                        state: { user_id: student.user?.id ? student.user?.id : student?._source.user_id, profileName: student.user?.name ? student.user?.name : student._source?.name }
                      }}>
                        {this.props.pagewiseLanguageData.view_profile}
                      </Link>
                      <div className="title-grid4">
                        {this.state.invite && <input type="checkbox" onClick={() => this.changeIds(student.id)}
                          defaultChecked={this.state.user_ids.indexOf(student.id) !== -1 ? true : false} />}
                        {this.state.resend && <input type="checkbox" onClick={() => this.changeIds(student.user.id)}
                          defaultChecked={this.state.user_ids.indexOf(student.user.id) !== -1 ? true : false} />}
                        {!student?.user?.profile_picture || student.user?.profile_picture === null || student.user?.profile_picture === ''
                          ? <img src={Profile} alt="dp" /> : <div className="dp_div"
                            style={{ backgroundImage: "url(" + common.addFileUrl(student.user?.profile_picture) + ")" }}>
                          </div>}
                        <div>
                          {student.user?.name ? student.user?.name : student._source?.name}
                        </div>
                      </div>
                      <div id="grid-up-span" className="userEmail"><b>{student.user?.email ? student.user?.email : student._source?.email}</b></div>
                      <div id="grid-up-span" className="userEmail">{student.section_details?.name ? student.class_details?.name : student._source?.class_label}</div>
                      <div id="grid-up-span" className="userEmail">{student.section_details?.name ? student.section_details?.name : student._source?.batch_label}</div>
                    </div>

                  </div>
                )}
              </div>}</>}</>}
          </>}
        </div>
        {!this.state.open3 && this.state.filterStudents.length > 0 && <Pagination
          activePage={this.state.current_page}
          itemsCountPerPage={20}
          totalItemsCount={this.state.totalItems}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange.bind(this)}
        />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getStudentList, userSearchList, createStudent, updateStudent, getCenterList, sendInvite,
    getClassList2, getAdminProfile, getSectionList2, userdetail, classbatchmapping, deleteStudent, sendInvitePassword, bulkUserCreate, bulkUserJobID, userResetPassword, studentActiveInactive,
    bulkPasswordUpdate, getPageLanguageWiseData, getStudentListActiveInactive, studentPasswordUpdate,
    getStdClearCache
  } = actions;

  return {
    sendInvite: (mail_type, user_ids) => dispatch(sendInvite(mail_type, user_ids)),
    sendInvitePassword: (user_ids) => dispatch(sendInvitePassword(user_ids)),
    createStudent: (id, name, email, mobile, password, center_id, roll_no) => dispatch(createStudent(id, name, email, mobile, password, center_id, roll_no)),
    updateStudent: (id, name, email, mobile, password, classs, profile_picture, section, username) => dispatch(updateStudent(id, name, email, mobile, password, classs, profile_picture, section, username)),
    getStudentList: (center_id, filterClass, filterSection, page) => dispatch(getStudentList(center_id, filterClass, filterSection, page)),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getStudentListActiveInactive: (center_id, filterClass, filterSection, page, is_active) => dispatch(getStudentListActiveInactive(center_id, filterClass, filterSection, page, is_active)),
    getCenterList: () => dispatch(getCenterList()),
    getClassList2: () => dispatch(getClassList2()),
    deleteStudent: (id, center_id) => dispatch(deleteStudent(id, center_id)),
    userSearchList: (key, page) => dispatch(userSearchList(key, page)),
    userdetail: (key) => dispatch(userdetail(key)),
    getAdminProfile: () => dispatch(getAdminProfile()),
    classbatchmapping: (center_id, user_id, class_batch_mapping) => dispatch(classbatchmapping(center_id, user_id, class_batch_mapping)),
    bulkUserCreate: (uploadfile) => dispatch(bulkUserCreate(uploadfile)),
    bulkUserJobID: (jobid) => dispatch(bulkUserJobID(jobid)),
    userResetPassword: (user_id, password, language, updated_by) => dispatch(userResetPassword(user_id, password, language, updated_by)),
    studentActiveInactive: (user_id, is_active) => dispatch(studentActiveInactive(user_id, is_active)),
    bulkPasswordUpdate: (uploadfile) => dispatch(bulkPasswordUpdate(uploadfile)),
    getPageLanguageWiseData: (language, page) => dispatch(getPageLanguageWiseData(language, page)),
    studentPasswordUpdate: (class_id, batch_id, new_password) => dispatch(studentPasswordUpdate(class_id, batch_id, new_password)),
    getStdClearCache: (center_id) => dispatch(getStdClearCache(center_id)),


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Students);

// import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import ReactNotifications from 'react-notifications-component';
// import { Helmet } from "react-helmet";
// import ReactPlayer from 'react-player';
// import actions from '../../redux/actions';
// import './styles.css';
// import CustomVideoPlayer from '../../components/players/video/CustomVideoPlayer';
// const ViewRecording = (props) => {
//   const location = useLocation();
//   const [recordingUrl, setRecordingUrl] = useState(location.state?.recordingUrl || localStorage.getItem("recordingUrl") || '');
//   const [lectureId, setLectureId] = useState(location.state?.lecture_id || localStorage.getItem("lectureId") || '');
//   const [videoDetail, setVideoDetail] = useState(JSON.parse(location.state?.videoDetail || localStorage.getItem("videoDetail") || '{}'));

//   useEffect(() => {
//     const url = window.location.pathname;
//     const getid = url.substring(url.lastIndexOf('/') + 1);
//     console.log("getid", getid);
//     console.log("recordingUrl", location.state?.recordingUrl);

//     document.addEventListener('contextmenu', (e) => e.preventDefault());

//     const ctrlShiftKey = (e, keyCode) => e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);

//     document.onkeydown = (e) => {
//       if (
//         e.keyCode === 123 ||
//         ctrlShiftKey(e, 'I') ||
//         ctrlShiftKey(e, 'J') ||
//         ctrlShiftKey(e, 'C') ||
//         (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
//       ) return false;
//     };

//     return () => {
//       document.removeEventListener('contextmenu', (e) => e.preventDefault());
//     }
//   }, [recordingUrl]);

//   return (
//     <div className="viewRecordingWrapper">
//       <Helmet>
//         <title>View Recording</title>
//       </Helmet>
//       <ReactNotifications />

//       <div className='app-body'>
//         <div className='app-div'>
//           <button
//             style={{ marginBottom: '10px' }}
//             onClick={() => window.history.back()}
//           >
//             Back
//           </button>
//           <h1>{videoDetail?.subject}</h1>
//           <h3>{videoDetail?.title} | {videoDetail?.faculty} </h3>
//           {recordingUrl ? (
//             <>
//               {recordingUrl.includes('.mp4') ? (
//                 // <ReactPlayer
//                 //   config={{ file: { attributes: { controlsList: 'nodownload' } } }}
//                 //   url={recordingUrl}
//                 //   controls={true}
//                 //   width="100%"
//                 //   height="500px"
//                 //   className="react-player"
//                 //   autoplay={true}
//                 // />
//                 <CustomVideoPlayer url={recordingUrl}  video_content_type ='video'
//                   lecture_id={location.state?.lecture_id}
//                   onError={() => {
//                   }}
//                 />
//               ) : (
//                 <iframe allowfullscreen="1" src={recordingUrl}></iframe>
//               )}
//             </>
//           ) : (
//             <div className='unauthorizedAccessDialog'>
//               <div className='unauthorizedAccessContainer'>
//                 Unauthorized access <br /> You do not have the required permissions.
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   )
// };

// const mapStateToProps = (state) => ({
//   loading: state.commonReducer.loading,
// });

// const mapDispatchToProps = (dispatch) => ({
//   getStudentLectureList: () => dispatch(actions.getStudentLectureList()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(ViewRecording);

import { useEffect, useState } from "react"
import React from "react"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import ReactNotifications from "react-notifications-component"
import { Helmet } from "react-helmet"
import actions from "../../redux/actions"
import "./styles.css"
import CustomVideoPlayer from "../../components/players/video/CustomVideoPlayer"
import Back from "../../public/icons/back.svg"

const ViewRecording = (props) => {
  const location = useLocation()
  const [recordingUrl, setRecordingUrl] = useState(
    location.state?.recordingUrl || localStorage.getItem("recordingUrl") || "",
  )
  const [lectureId, setLectureId] = useState(location.state?.lecture_id || localStorage.getItem("lectureId") || "")
  const [videoDetail, setVideoDetail] = useState(
    JSON.parse(location.state?.videoDetail || localStorage.getItem("videoDetail") || "{}"),
  )

  useEffect(() => {
    const url = window.location.pathname
    const getid = url.substring(url.lastIndexOf("/") + 1)
    console.log("getid", getid)
    console.log("recordingUrl", location.state?.recordingUrl)

    document.addEventListener("contextmenu", (e) => e.preventDefault())

    const ctrlShiftKey = (e, keyCode) => e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0)

    document.onkeydown = (e) => {
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
      )
        return false
    }

    return () => {
      document.removeEventListener("contextmenu", (e) => e.preventDefault())
    }
  }, [recordingUrl])

  return (
    <div className='app-body'>
      <div className='app-div'></div>

      <div
        style={{
          width: "100%",
          maxWidth: "1200px",

          padding: "20px",
          backgroundColor: "#f8f9fa",
          minHeight: "120vh",
          boxSizing: "border-box",
        }}
      >
        <Helmet>
          <title>View Recording</title>
        </Helmet>
        <ReactNotifications />

        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            padding: "20px",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                marginBottom: "15px",
              }}
            >
              <img
                src={Back || "/placeholder.svg"}
                alt="Back"
                style={{
                  cursor: "pointer",
                  width: "28px",
                  height: "28px",
                  padding: "5px",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "50%",
                  transition: "background-color 0.2s",
                }}
                onClick={() => window.history.back()}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#e0e0e0")}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
              />
              <h1
                style={{
                  margin: 0,
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                {videoDetail?.subject || "Video Recording"}
              </h1>
            </div>

            <h3
              style={{
                margin: "0 0 20px 0",
                fontSize: "18px",
                fontWeight: "500",
                color: "#555",
              }}
            >
              {videoDetail?.title || ""} {videoDetail?.faculty ? `| ${videoDetail.faculty}` : ""}
            </h3>

            {recordingUrl ? (
              <div
                style={{
                  width: "100%",
                  borderRadius: "6px",
                  overflow: "hidden",
                  backgroundColor: "#000",
                  position: "relative",
                  aspectRatio: "16/9",
                  maxHeight: "70vh",
                }}
              >
                {recordingUrl.includes(".mp4") ? (
                  <CustomVideoPlayer
                    url={recordingUrl}
                    video_content_type="video"
                    lecture_id={location.state?.lecture_id}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#000",
                      borderRadius: "6px",
                    }}
                    onError={() => {
                      console.error("Video playback error")
                    }}
                    controlsConfig={{
                      showPlayPause: true,
                      showProgress: true,
                      showVolume: true,
                      showFullscreen: true,
                      showDownload: false,
                    }}
                  />
                ) : (
                  <iframe
                    src={recordingUrl}
                    allowFullScreen={true}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      borderRadius: "6px",
                    }}
                  ></iframe>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f8d7da",
                  color: "#721c24",
                  padding: "40px 20px",
                  borderRadius: "6px",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "500",
                  minHeight: "200px",
                }}
              >
                <div>
                  Unauthorized access <br />
                  You do not have the required permissions.
                </div>
              </div>
            )}
          </div>

          {videoDetail?.description && (
            <div
              style={{
                marginTop: "20px",
                padding: "15px",
                backgroundColor: "#f8f9fa",
                borderRadius: "6px",
                fontSize: "16px",
                lineHeight: "1.6",
                color: "#555",
              }}
            >
              <h4 style={{ margin: "0 0 10px 0", color: "#333" }}>Description</h4>
              <p style={{ margin: 0 }}>{videoDetail.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>

  )
}

const mapStateToProps = (state) => ({
  loading: state.commonReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  getStudentLectureList: () => dispatch(actions.getStudentLectureList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewRecording)


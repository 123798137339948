import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import CustomVideoPlayer from './CustomVideoPlayer';

const ReactVideoPlayer = (props) => {

    const location = useLocation();
    const { selectedVideo, lecture_id } = location.state || {};  // Retrieve the state passed in Link
    const videoDetail = JSON.parse(localStorage.getItem("videoDetail"));  // Retrieve the video details from localStorage

    // Check if the URL needs transformation for .mp4 or not
    const [videoUrl, setVideoUrl] = useState(() => {
        if (selectedVideo && selectedVideo.includes('.mp4')) {
            return selectedVideo.replace('veranda-1-prod-lms-videos.s3.ap-south-1.amazonaws.com', 'verandacdn.verandahighered.com');
        }
        return selectedVideo;
    });

    return (
        <div className='app-body'>
            <div className='app-div'>
                <button style={{ marginBottom: '10px' }} onClick={() => window.history.back()}>Back</button>
                <h1>{videoDetail?.title}</h1>
                <h3>{videoDetail?.batch_name} </h3>
                {
                    // If the video is an MP4 file, use ReactPlayer
                    selectedVideo.includes('.mp4') 
                    ? <CustomVideoPlayer url={videoUrl}
                    lecture_id={lecture_id}
                    onError={() => {
                          setVideoUrl(selectedVideo); 
                    }}
                    props={props} 
                    />
                    : 
                    <iframe src={selectedVideo} width="100%" height="586px" />
                }

            </div>
        </div>
    );
};

export default ReactVideoPlayer;

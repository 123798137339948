import React, { Component } from 'react';
import Calendar from 'react-calendar';
import 'react-circular-progressbar/dist/styles.css';
import { Helmet } from "react-helmet";
import Pagination from "react-js-pagination";
import ReactNotifications from 'react-notifications-component';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import Calendar2 from '../../public/icons/calendar2.svg';
import reportsLoader from '../../public/icons/loader.png';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import PathB from '../../public/icons/path-b.svg';
import PathD from '../../public/icons/path-d.svg';
import actions from '../../redux/actions';
import CourseDashboard from './CourseDashboard';
import './styles.css';
import { Link } from 'react-router-dom';



class AttendanceReports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      batch_id: '',
      current_date_records: [],
      current_date: new Date(),
      showCalendar: false,
      saveMonthNumber: '',
      saveMonthName: '',
      saveYear: '',
      attendanceList: [],
      // studentBatchList: [],
      studentBatchList: {
        student_batch: []
      },
      selectedBatchLabel: '',

      token: localStorage.getItem('token'),
      getUserAllData: [],
      current_page: 0,
      totalItems: 0,
      attendanceListDisplay: true,
      showCourseDashboard: this.props?.location?.state?.openCourseDashboard ? null : false,
      classs: JSON.parse(localStorage.getItem("logindetails")),
      reportList: null,
      activeIndex: null,
      activeWeek1: '',
      activeWeek2: '',
      activeWeek3: '',
      activeWeek4: '',
      loaderShow: true,


      courseDashboardOpen: false,
      recordingListDisplay: false,

    }

    this.wrapperRef = React.createRef();
  }




  componentDidMount() {

    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.state.current_date) {
      setTimeout(() => {
        this.getAllData();
        this.getStudentBatchList();


      }, 1000);
    }

    if (this.props?.location?.state?.recordingListDisplay == true) {
      this.setState({ attendanceListDisplay: false })
      this.setState({ recordingListDisplay: true })
      this.setState({ courseDashboardOpen: false })
    }


  }




  getAssessmentReport = async (class_id) => {
    const response = await this.props.getAssessmentReport(class_id, 'course_completion')
    console.log(response);
    console.log(response?.status);
    if (response?.status == 1) {
      console.log(response);
      this.setState({ reportList: response?.data })
    }
  }


  handleSubjectChange = (e) => {
    const subject_id = e.target.value;
  }

  getAllData = async (pageNumber) => {
    console.log("pageNumber", pageNumber)
    const { batch_id } = this.state;
    const response = await this.props.learnerAttendanceAllReports(this.getDate(this.state.current_date), 'backward', pageNumber ? pageNumber : 1, batch_id)
    console.log(response);
    console.log(response?.status);
    if (response?.status == 1) {
      this.setState({ loaderShow: false })
      this.setState({ attendanceList: response?.data?.data, current_page: response.data?.pagination?.current_page, totalItems: response.data?.pagination?.total_page * 20 })
      // this.setState({ studentBatchList: response?.data, current_page: response.data?.pagination?.current_page, totalItems: response.data?.pagination?.total_page * 20 })

    }
  }

  // getStudentBatchList = async (pageNumber) => {
  //   console.log("pageNumber", pageNumber)
  //   const response = await this.props.learnerAttendanceAllReports(this.getDate(this.state.current_date), 'backward', pageNumber ? pageNumber : 1)
  //   console.log(response);
  //   console.log(response?.status);
  //   if (response?.status == 1) {
  //     this.setState({ loaderShow: false })
  //     // this.setState({ attendanceList: response?.data?.data, current_page: response.data?.pagination?.current_page, totalItems: response.data?.pagination?.total_page * 20 })
  //   this.setState({ studentBatchList: response?.data??[], current_page: response.data?.pagination?.current_page, totalItems: response.data?.pagination?.total_page * 20 })

  //   }
  // }
  getStudentBatchList = async (pageNumber) => {
    const response = await this.props.learnerAttendanceAllReports(this.getDate(this.state.current_date), 'backward', pageNumber || 1, this.state.selectedBatchId);
    if (response?.status == 1 && response?.data) {
      // Ensure you set the correct path to `student_batch`
      this.setState({
        studentBatchList: response?.data ?? { student_batch: [] },
        loaderShow: false,
      });
    }
  }


  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }


  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      this.setState({ showCalendar: false })
    }
  }


  getDate(value) {
    console.log("value getDate", value)
    const today = value;
    const monthName = today.toLocaleString('default', { month: 'short' });
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    this.setState({ saveMonthNumber: month });
    this.setState({ saveMonthName: monthName });
    this.setState({ saveYear: year });
    return `${year}-${month}-${date}`;
  }

  openCalendar = () => {
    this.setState({ showCalendar: true });
  }

  // CalenderOnChange = (value, event) =>{
  //   this.setState({current_date:value});
  //   setTimeout(() => {
  //     this.getAllData()
  //   }, 200);

  // }

  CalenderOnChange = (value, event) => {
    console.log("value", value);
    const year = value.getFullYear();
    const month = value.getMonth();
    const firstDayNextMonth = new Date(year, month + 1, 1);

    // Subtract one day to get the last day of the current month
    const lastDayOfMonth = new Date(firstDayNextMonth - 1);

    console.log("firstDayNextMonth", firstDayNextMonth)
    console.log("lastDayOfMonth", lastDayOfMonth)

    this.setState({ current_date: lastDayOfMonth });
    setTimeout(() => {
      this.getAllData()
    }, 200);
    this.setState({ showCalendar: false });
  }

  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ onLoad: true })
    this.getAllData(pageNumber);
    this.setState({ current_page: pageNumber });

  }

  convertStartTime = (value) => {
    const today = value;
    const indexOfT = today.indexOf('T');
    const dateonly = today.substring(0, indexOfT);
    const timeonly = today.substring(indexOfT + 1, today.length).substring(0, 5);
    console.log("dateonly", dateonly);
    console.log("timeonly", timeonly);
    return timeonly;
  }

  displayDateTime(start_date_time, end_date_time) {
    const startDateTime = new Date(start_date_time);
    const endDateTime = new Date(end_date_time);
    const startDate = startDateTime.toLocaleDateString('en-GB'); // 'en-GB' for day-month-year format
    const startTime = startDateTime.toTimeString().slice(0, 5); // Get HH:MM from start_date_time
    const endTime = endDateTime.toTimeString().slice(0, 5); // Get HH:MM from end_date_time
    const formattedDateTime = `${startDate} - ${startTime} to ${endTime}`;
    return `${formattedDateTime}`;
  }


  formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('default', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    return `${formattedDate} at ${formattedTime}`;
  }



  TabClick(tabName) {
    console.log(tabName);
    if (tabName == "attendance") {
      this.setState({ attendanceListDisplay: true })
      this.setState({ recordingListDisplay: false })
      this.setState({ courseDashboardOpen: false })
    }
    if (tabName == "recording") {
      this.setState({ attendanceListDisplay: false })
      this.setState({ recordingListDisplay: true })
      this.setState({ courseDashboardOpen: false })
    }
    if (tabName == "courseDashboard") {
      this.setState({ attendanceListDisplay: false })
      this.setState({ recordingListDisplay: false })
      this.setState({ courseDashboardOpen: true })

    }
  }
  handleClassChange = (e) => {
    this.getAssessmentReport(e.target.value)
  }


  onTitleClick = (index) => {
    this.setState({ activeIndex: index === this.state.activeIndex ? null : index });
  };



  weekClick = (getWeekno) => {
    if (getWeekno == 1) {
      this.setState({ activeWeek1: !this.state.activeWeek1 });
    } else {
      this.setState({ activeWeek1: '' });
    }


    if (getWeekno == 2) {
      this.setState({ activeWeek2: !this.state.activeWeek2 });
    } else {
      this.setState({ activeWeek2: '' });
    }

    if (getWeekno == 3) {
      this.setState({ activeWeek3: !this.state.activeWeek3 });
    } else {
      this.setState({ activeWeek3: '' });
    }

    if (getWeekno == 4) {
      this.setState({ activeWeek4: !this.state.activeWeek4 });
    } else {
      this.setState({ activeWeek4: '' });
    }

  }


  onBatchChange = async (e, pageNumber) => {
    const selectedBatchId = e.target.value;

    const selectedBatchLabel = this.state.studentBatchList?.student_batch?.find(batchItem => batchItem.id.toString() === selectedBatchId)?.label || '';


    console.log("Selected Batch ID:", selectedBatchId);
    console.log("Selected Batch Label:", selectedBatchLabel);

    this.setState({
      batch_id: selectedBatchId,
      selectedBatchLabel: selectedBatchLabel, // Update the state with the selected batch label
      loaderShow: true,
    });

    const response = await this.props.learnerAttendanceAllReports(this.getDate(this.state.current_date), 'backward', pageNumber ? pageNumber : 1, selectedBatchId);
    console.log(response);
    console.log(response?.status);
    if (response?.status == 1) {
      this.setState({
        loaderShow: false,
        attendanceList: response?.data.data,
        current_page: response.data?.pagination?.current_page,
        totalItems: response.data?.pagination?.total_page * 20
      });
      console.log('student list data is :', response?.data);
    }
  };





  render() {

    let allClasses = JSON.parse(localStorage.getItem("logindetails"))?.classs;
    const today = new Date();
    const twoYearsAgo = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate());
    const isEnabled = this.state.class_id !== '' && this.state.subject_id !== '';
    const customStyles = {
      headCells: {
        style: {
          fontFamily: 'Lato', // override the row height
          fontSize: '14px',
          fontWeight: 700,
        },
      },
      cells: {
        style: {
          fontFamily: 'Lato', // override the row height
          fontSize: '16px',
          fontWeight: 400,
        },
      },

    }
    return (
      <div id="appBody" className='app-body learnerAttendance-listing'>
        <ReactNotifications />

        <div className="app-div">
          <Helmet>
            <title>Attendance List - Learner</title>
          </Helmet>
          <Popup open={this.state.getUrl} closeOnDocumentClick onClose={this.closeUrl} className="content-geturl">
            <span className="close" onClick={this.closeUrl}>&#x2715;</span>
            <div className="url-child1">GET URL</div>
            <div className="url-child2">{this.state?.open_url}</div>
          </Popup>
          <img id="path-b" src={PathB} alt="pathb" />
          <img id="path-d" src={PathD} alt="pathd" />
          <div className="page-header-faculty">


            {/*Assessments Reports start*/}
            <div className="attendanceReportsWrapper">
              <div className="attendanceReportsContainer">

                <div className='tabsWrapper'>
                  <span className={this.state.courseDashboardOpen == true ? 'selected' : ''} onClick={() => this.TabClick('courseDashboard')}>Course Dashboard</span>
                  <span className={this.state.attendanceListDisplay == true ? 'selected' : ''} onClick={() => this.TabClick('attendance')}>Attendance List</span>
                  <span className={this.state.recordingListDisplay == true ? 'selected' : ''} onClick={() => this.TabClick('recording')}>Recording List</span>
                </div>

                {this.state.courseDashboardOpen &&
                  <CourseDashboard user_id={JSON.parse(localStorage.getItem('logindetails'))?.id} />
                }


                {/*Attendance List Reports start*/}
                {this.state.attendanceListDisplay == true &&
                  <div className='AttendanceListWrapper'>

                    <h2>Attendance List</h2>
                    <div className='header-op'>
                      <div className='calenderBlock'>
                        <div className='currentDate'>
                          <div>
                            <div id="trace-back" onClick={() => this.props.history.goBack()}>
                              <img src={BackArrow} alt="back" />
                            </div>
                            {this.state.saveMonthName} {this.state.saveYear}
                            <div className="react-calendar-date" onClick={this.openCalendar}
                              ref={this.wrapperRef}>
                              <span>
                                <img src={Calendar2} alt="cal" />
                              </span>

                            </div>

                            <div style={{ width: '200px' }}>
                              <select onChange={this.onBatchChange} value={this.selectedBatchId}>
                                <option value="">Select a batch</option>
                                {this.state.studentBatchList?.student_batch?.map(batchItem => (
                                  <option value={batchItem.id} key={batchItem.id}>
                                    {batchItem.label}
                                  </option>
                                ))}
                              </select>





                            </div>
                            <div style={{
                              // marginRight: '200px',
                              alignItems: 'center',
                              marginTop: '100px',
                              // width: '400px',
                              whiteSpace: 'nowrap',
                              // overflow: 'hidden',
                              // textOverflow: 'ellipsis'
                            }}>
                              {this.state.selectedBatchLabel && <span>Selected Batch: {this.state.selectedBatchLabel}</span>}
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                    <div ref={this.wrapperRef} className={this.state.showCalendar ? "calendarView" : "calendarView hide"} >
                      {/* <Calendar onClickMonth={this.onClickMonth}  nextLabel={null} next2Label={null}  prev2Label={null}  prevLabel={null} value={this.state.current_date} defaultView='year' maxDetail="year"/> */}
                      <Calendar nextLabel={null} next2Label={null} prev2Label={null} prevLabel={null} defaultView='year' maxDetail="year" onChange={this.CalenderOnChange} value={this.state.current_date} />
                    </div>
                    <div className='attendanceListingTableWrapper'>
                      <ol>
                        {/* {JSON.stringify(this.state.attendanceList.data)} */}
                        {this.state.loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}
                        {this.state.attendanceList && this.state.attendanceList.length > 0 && this.state.attendanceList.map((items, index) =>
                          <li key={index}>
                            <div className='info'>
                              <h2>{items?.title}</h2>
                              <div className='className'>Class - <span>{items?.class_name}</span></div>
                              <div className='batchName'>Batch - <span>{items?.batch_name}</span></div>
                              {items?.start_date_time && <div className='startTime'>Date - <span>{this.displayDateTime(items?.start_date_time, items?.end_date_time)}</span></div>}
                            </div>
                            <div className='status'>
                              {items?.attendance == "Absent" && <div className='status-absent'><span>{items?.attendance}</span></div>}
                              {items?.attendance == "Present" && <div className='status-present'><span>{items?.attendance}</span></div>}
                            </div>
                          </li>
                        )}
                      </ol>




                      {this.state.attendanceList.length == 0 && !this.state.loaderShow && <div className='noDataFound'>No Attendance Found</div>}

                      {this.state.attendanceList?.length ? <div className="pagination-list">
                        <Pagination
                          activePage={this.state.current_page}
                          itemsCountPerPage={20}
                          totalItemsCount={this.state.totalItems}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div> : ''}
                    </div>


                  </div>
                }
                {/*Attendance List Reports start*/}


                {/*Recording List Reports start*/}
                {this.state.recordingListDisplay == true &&
                  <div className='RecordingListWrapper'>
                    <div className="attendanceReportsWrapper">
                      <div className="attendanceReportsContainer">
                        <h2>Recording List</h2>
                        <div className='header-op'>
                          <div className='calenderBlock'>
                            <div className='currentDate'>
                              <div>
                                <div id="trace-back" onClick={() => this.props.history.goBack()}>
                                  <img src={BackArrow} alt="back" />
                                </div>
                                {this.state.saveMonthName} {this.state.saveYear}
                                <div className="react-calendar-date" onClick={this.openCalendar}
                                  ref={this.wrapperRef}>
                                  <span>
                                    <img src={Calendar2} alt="cal" />
                                  </span>

                                </div>
                                <div className='filterBatch' style={{ width: '200px' }}>
                                  <select onChange={this.onBatchChange} value={this.selectedBatchId}>
                                    <option value="">Select a batch</option>
                                    {this.state.studentBatchList?.student_batch?.map(batchItem => (
                                      <option value={batchItem.id} key={batchItem.id}>
                                        {batchItem.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div style={{
                                  // marginRight: '200px',
                                  alignItems: 'center',
                                  marginTop: '100px',
                                  // width: '400px',
                                  whiteSpace: 'nowrap',
                                  // overflow: 'hidden',
                                  // textOverflow: 'ellipsis'
                                }}>
                                  {this.state.selectedBatchLabel && <span>Selected Batch: {this.state.selectedBatchLabel}</span>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div ref={this.wrapperRef} className={this.state.showCalendar ? "calendarView" : "calendarView hide"} >
                          {/* <Calendar onClickMonth={this.onClickMonth}  nextLabel={null} next2Label={null}  prev2Label={null}  prevLabel={null} value={this.state.current_date} defaultView='year' maxDetail="year"/> */}
                          <Calendar nextLabel={null} next2Label={null} prev2Label={null} prevLabel={null} defaultView='year' maxDetail="year" onChange={this.CalenderOnChange} value={this.state.current_date} />
                        </div>
                        <div className='attendanceListingTableWrapper'>
                          <ol>
                            {/* {JSON.stringify(this.state.attendanceList.data)} */}
                            {this.state.attendanceList && this.state.attendanceList.length > 0 && this.state.attendanceList.map((items, index) =>
                              <li key={index}>
                                <div className='info'>
                                  <h2>{items?.title}</h2>
                                  <div className='className'>Class - <span>{items?.class_name}</span></div>
                                  <div className='batchName'>Batch - <span>{items?.batch_name}</span></div>
                                  <div className='className'>Duration - <span>{items?.duration_hrs} hours</span></div>
                                  {items?.start_date_time && <div className='startTime'>Date - <span>{this.displayDateTime(items?.start_date_time, items?.end_date_time)}</span></div>}

                                </div>
                                <div className='status'>
                                  {items.record_url && <div className='viewRecording'>
                                    {/* {items.record_url.includes('highered.edureka.co') ? (
                                      <a
                                        target="_blank"
                                        href={items?.record_url}
                                      >
                                        View Recording
                                      </a>
                                    )

                                      : (<a
                                        target="_blank"
                                        href={`https://ecom-highered.edureka.co/public_content/${items?.record_url?.split('/').slice(-2, -1)[0]}`}
                                      >
                                        View Recording
                                      </a>)} */}

                                    <Link 
                                    onClick={()=> {
                                      localStorage.setItem("videoDetail", JSON.stringify(items))
                                    }}
                                    to={{pathname: `/learner/video`, state: {selectedVideo: items.record_url, lecture_id: items?.id }}} >View Recording</Link>
                                    {items.viewed_time != null && <div className='viewedTime'>Viewed - <span>{this.formatDateTime(items.viewed_time)}</span></div>}
                                  </div>}
                                </div>
                              </li>
                            )}
                          </ol>

                          {this.state.loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}

                          {this.state.attendanceList.length == 0 && !this.state.loaderShow && <div className='noDataFound'>No Recording Found</div>}

                          {this.state.attendanceList?.length ? <div className="pagination-list">
                            <Pagination
                              activePage={this.state.current_page}
                              itemsCountPerPage={20}
                              totalItemsCount={this.state.totalItems}
                              pageRangeDisplayed={10}
                              onChange={this.handlePageChange.bind(this)}
                            />
                          </div> : ''}
                        </div>


                      </div>
                    </div>
                  </div>
                }
                {/*Recording Reports start*/}





              </div>
            </div>
            {/*Assessments Reports start*/}









          </div>

        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { learnerAttendanceAllReports, getAssessmentReport } = actions;
  return {
    learnerAttendanceAllReports: (start_date, direction, pageNumber, batch_id) => dispatch(learnerAttendanceAllReports(start_date, direction, pageNumber, batch_id)),
    getAssessmentReport: (class_id, type, download_type) => dispatch(getAssessmentReport(class_id, type, download_type)),



  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceReports);
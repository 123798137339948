export const center_id_data = {
    "data": [
        {
            "id": 13,
            "key": "sub_domain",
            "value": "vstage",
            "status": 1,
            "ext": "vstage",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 14,
            "key": "logo_url",
            "value": "https://lms-veranda.s3.ap-south-1.amazonaws.com/center-logos/SASTRA_LOGO_1_kk30Kh0.jpg",
            "status": 1,
            "ext": "https://lms-veranda.s3.ap-south-1.amazonaws.com/center-logos/SASTRA_LOGO_1_kk30Kh0.jpg",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 15,
            "key": "primary_color",
            "value": "#2e3192",
            "status": 1,
            "ext": "#2e3192",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 16,
            "key": "secondary_color",
            "value": "#00aeed",
            "status": 1,
            "ext": "#00aeed",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 17,
            "key": "primary_color_light",
            "value": "#00aeed4d",
            "status": 1,
            "ext": "#00aeed4d",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 18,
            "key": "login_banner",
            "value": [
                {
                    "url": "https://skyed.schoolsonweb.com/static/media/login-banner1.7de6f5af.jpg",
                    "title": "360 degree learning approach",
                    "desc": "Anywhere Anytime Learning to help students realise their dreams"
                },
                {
                    "url": "https://skyed.schoolsonweb.com/static/media/login-banner2.4de21f09.jpg",
                    "title": "India's strongest mentorship ecosystem",
                    "desc": "Learn from subject-matter expects and real-time live classes"
                },
                {
                    "url": "https://skyed.schoolsonweb.com/static/media/login-banner3.73b580d0.jpg",
                    "title": "A Truly World-class Learning",
                    "desc": "future-ready curriculum designed to ensure success"
                },
                {
                    "url": "https://skyed.schoolsonweb.com/static/media/login-banner4.28cbf616.jpg",
                    "title": "Extensive Practice Programs",
                    "desc": "Competitive opportunities simulated to practice real-time exams"
                }
            ],
            "status": 1,
            "ext": {},
            "center_id": 3,
            "options": {
                "type": "json"
            }
        },
        {
            "id": 49,
            "key": "fav_icon",
            "value": "https://api.verandahighered.com/media/center-logos/SASTRA_LOGO_1_kk30Kh0_TzMj7ND.jpg",
            "status": 1,
            "ext": "https://api.verandahighered.com/media/center-logos/SASTRA_LOGO_1_kk30Kh0_TzMj7ND.jpg",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 50,
            "key": "institute_languages",
            "value": [
                {
                    "label": "English",
                    "code": "en"
                }
            ],
            "status": 1,
            "ext": {},
            "center_id": 3,
            "options": {
                "type": "json"
            }
        },
        {
            "id": 62,
            "key": "allowed_file_extensions",
            "value": "jpg,jpeg,gif,png,pdf,zip,rar,doc,docx,ppt,pptx,xls,xlsx,mp4",
            "status": 1,
            "ext": "jpg,jpeg,gif,png,pdf,zip,rar,doc,docx,ppt,pptx,xls,xlsx,mp4",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 90,
            "key": "allowed_file_extensions_for_notes",
            "value": "docx,ppt,pptx,xlsx,csv,txt,sav,pdf,html,rar,zip",
            "status": 1,
            "ext": "docx,ppt,pptx,xlsx,csv,txt,sav,pdf,html,rar,zip",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 97,
            "key": "sub_domain",
            "value": "python",
            "status": 1,
            "ext": "python",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 98,
            "key": "sub_domain",
            "value": "vapi",
            "status": 1,
            "ext": "vapi",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        },
        {
            "id": 120,
            "key": "enable_toc_accordian",
            "value": "1",
            "status": 1,
            "ext": "1",
            "center_id": 3,
            "options": {
                "type": "integer"
            }
        },
        {
            "id": 168,
            "key": "cc_mail",
            "value": "['mrujjwal5654@gmail.com','ujjwal2019@ismpatna.ac.in']",
            "status": 1,
            "ext": "['mrujjwal5654@gmail.com','ujjwal2019@ismpatna.ac.in']",
            "center_id": 3,
            "options": {
                "type": "string"
            }
        }
    ],
    "error": [
        "Success"
    ],
    "status": 1,
    "currentDateTime": "2024-09-26T15:30:09+05:30"
}
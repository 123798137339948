import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import Header from '../../components/navigation/Header';
import Add from '../../public/icons/add.svg';
import LinkIcon from '../../public/icons/view_link.png';
import { useParams } from "react-router-dom";

const HLSReport = ({ getHlsReport, getSaveHlsUrl }) => {
    const [hlsReport, setHlsReport] = useState([]);
    const [modalData, setModalData] = useState(null);
    const [selectedContentType, setSelectedContentType] = useState('video');
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState({ total_pages: 1 });
    const { type } = useParams();
    const isVideo = type === "video";

    useEffect(() => {
        fetchHlsReportList();
    }, [selectedContentType, currentPage]);

    const fetchHlsReportList = async () => {
        try {
            const response = await getHlsReport(selectedContentType, currentPage);
            setHlsReport(response.data);
            setPagination(response.pagination);
        } catch (error) {
            console.error("Failed to fetch HLS report list:", error);
        }
    };

    const handleSave = async () => {
        try {
            await getSaveHlsUrl(modalData.contentType, modalData.hlsUrl, modalData.lectureId);
            alert("HLS report saved successfully!");
            handleCloseModal();
            fetchHlsReportList();
        } catch (error) {
            console.error("Error saving HLS report:", error);
        }
    };

    const handleOpenModal = () => {
        setModalData({ contentType: "video", hlsUrl: "", lectureId: "" });
    };

    const handleCloseModal = () => {
        setModalData(null);
    };

    return (
        <div className="admin-div" style={{ padding: "20px", fontFamily: "Arial" }}>
            <Header placeholder="Search classes" />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>HLS Video Reports</h2>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <select
                        value={selectedContentType}
                        onChange={(e) => setSelectedContentType(e.target.value)}
                        style={{ padding: "5px" }}
                    >
                        <option value="video">Video</option>
                        <option value="recording">Recording</option>
                    </select>
                    {/* <button
                        onClick={handleOpenModal}
                        style={{ display: "flex", alignItems: "center", padding: "8px 12px", borderRadius: "5px", background: "#000", color: "#fff", border: "none", cursor: "pointer" }}
                    >
                        <img src={Add} alt="Add" style={{ width: "16px", height: "16px", marginRight: "5px" }} />
                        Save HLS
                    </button> */}
                </div>
            </div>

            <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "left" }}>
                <thead>
                    <tr style={{ background: "#f4f4f4", borderBottom: "2px solid #ddd" }}>
                        <th style={{ padding: "10px" }}>Program Name</th>
                        <th style={{ padding: "10px" }}>Subject Name</th>
                        {selectedContentType !== 'video' && (
                            <>
                                <th style={{ padding: "10px" }}>Faculty</th>
                                <th style={{ padding: "10px" }}>Start Date</th>
                                <th style={{ padding: "10px" }}>Created Date</th>
                            </>
                        )}
                        <th style={{ padding: "10px" }}>HLS URL</th>
                    </tr>
                </thead>
                <tbody>
                    {hlsReport.length > 0 ? (
                        hlsReport.map((report) => (
                            <tr key={report.id} style={{ borderBottom: "1px solid #ddd" }}>
                                <td style={{ padding: "10px" }}>{report.name}</td>
                                <td style={{ padding: "10px" }}>{report.subject_name}</td>
                                {selectedContentType !== 'video' && (
                                    <>
                                        <td style={{ padding: "10px" }}>{report.faculty_name}</td>
                                        <td style={{ padding: "10px" }}>{report.start_date_time}</td>
                                        <td style={{ padding: "10px" }}>{report.created_at}</td>
                                    </>
                                )}

                                <td style={{ padding: "10px" }}>

                                    <a href={report.hls_url} target="_blank" rel="noopener noreferrer" className="flex items-center gap-2 text-blue-500 hover:underline">
                                        <img src={LinkIcon} height={25} width={25} />
                                        View Url
                                    </a>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" style={{ padding: "10px", textAlign: "center" }}>No records found</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Pagination */}
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", position: 'absolute', bottom: '10px', left: '50%', transform: 'translate(-50%, -50%)', alignItems: 'center' }}>

                <button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    style={{ margin: "0 5px", padding: "5px 10px" }}
                >
                    Prev
                </button>
                <span>Page {currentPage} of {pagination.total_pages}</span>
                <button
                    disabled={currentPage === pagination.total_pages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                    style={{ margin: "0 5px", padding: "5px 10px" }}
                >
                    Next
                </button>
            </div>

            {/* Modal */}
            {modalData && (
                <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ background: "#fff", padding: "20px", borderRadius: "10px", width: "400px" }}>
                        <h3>Save HLS</h3>
                        <label>Content Type</label>
                        <select value={modalData.contentType} onChange={(e) => setModalData({ ...modalData, contentType: e.target.value })} style={{ width: "100%", padding: "8px", marginBottom: "10px" }}>
                            <option value="video">video</option>
                            <option value="recording">recording</option>
                        </select>
                        <label>HLS URL</label>
                        <input type="text" value={modalData.hlsUrl} onChange={(e) => setModalData({ ...modalData, hlsUrl: e.target.value })} style={{ width: "100%", padding: "8px", marginBottom: "10px" }} />
                        <label>Lecture ID</label>
                        <input type="text" value={modalData.lectureId} onChange={(e) => setModalData({ ...modalData, lectureId: e.target.value })} style={{ width: "100%", padding: "8px", marginBottom: "10px" }} />
                        <button onClick={handleSave} style={{ width: "100%", padding: "10px", background: "#000", color: "#fff" }}>Save</button>
                        <button onClick={handleCloseModal} style={{ width: "100%", padding: "10px", marginTop: "10px", background: "#ddd" }}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    const { getHlsReport, getSaveHlsUrl } = actions;
    return {
        getSaveHlsUrl: (content_type, hls_url, c_id) => dispatch(getSaveHlsUrl(content_type, hls_url, c_id)),
        getHlsReport: (contentType, page) => dispatch(getHlsReport(contentType, page)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HLSReport);

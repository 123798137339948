import React from 'react';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import configureStore from './redux/configureStore';
import AppComponent from './AppComponent';
import config from "./config";
import withNetworkStatus from './components/internet/withNetworkStatus';
const store = configureStore();
const history = createBrowserHistory({ basename: '/app' });
const themePrimaryColor = config.themePrimaryColor;
const themeSecondaryColor = config.themeSecondaryColor;
const themePrimaryLightColor = config.themePrimaryLightColor;
if (window.ResizeObserver) {
  const observer = new ResizeObserver(() => {});
  observer.observe(document.body);
}


function App() {
  
  // document.documentElement.style.setProperty('--primary',themePrimaryColor);
  // document.documentElement.style.setProperty('--secondary',themeSecondaryColor);
  // document.documentElement.style.setProperty('--primary-rgba',themePrimaryLightColor);

  document.documentElement.style.setProperty('--primary', localStorage.getItem('primary_color') != 'undefined' ?  localStorage.getItem('primary_color'): themePrimaryColor);
  document.documentElement.style.setProperty('--secondary',localStorage.getItem('secondary_color')!= 'undefined' ? localStorage.getItem('secondary_color') : themeSecondaryColor);
  document.documentElement.style.setProperty('--primary-rgba',localStorage.getItem('primary_color_light')!= 'undefined' ?  localStorage.getItem('primary_color_light'): themePrimaryLightColor);


  let getLanguageFromLocal = localStorage.getItem("lang");
  document. documentElement. setAttribute("lang", getLanguageFromLocal);
  
  return (
  
    <Provider store={store}>
      <Router history={history}>
        <Route path='/' component={AppComponent}/>
      </Router>
    </Provider>
  
  );
}

export default withNetworkStatus(App);

import React, { useState, useRef, useEffect, useCallback } from 'react'
import './CustomVideoPlayer.css'
import { connect } from 'react-redux';
import actions from '../../../redux/actions';
import _ from 'lodash';
import Hls from 'hls.js'
import axios from 'axios';

const CustomVideoPlayer = ({ url, lecture_id, onError, updateRecordingVideoCompletion, getSaveHlsUrl,video_content_type }) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [volume, setVolume] = useState(1)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [playbackRate, setPlaybackRate] = useState(1)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isControlsVisible, setIsControlsVisible] = useState(true)
  const [prevPercentage, setPrevPercentage] = useState(-1);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [getHlsVideo, setHlsPlaylist] = useState('');
  const [covertToHls, setcovertToHls] = useState('');




  const togglePlay = () => {
    const video = videoRef.current
    if (video) {
      if (isPlaying) {
        video.pause()
      } else {
        video.play()
      }
      setIsPlaying(!isPlaying)
    }
  }


  const stop = () => {
    const video = videoRef.current
    if (video) {
      video.pause()
      video.currentTime = 0
      setIsPlaying(false)
    }
  }


  const seek = useCallback((time) => {
    const video = videoRef.current
    if (video && !Number.isNaN(time)) {
      video.currentTime = time
      setCurrentTime(time)
    }
  }, [videoRef])

  const changeVolume = (value) => {
    const video = videoRef.current
    if (video) {
      video.volume = value
      setVolume(value)
    }
  }





  useEffect(() => {
    const fetchHlsVideoPlaylist = async () => {
      if (!url) return;
      setLoading(true);
      setError(null);

      try {
        const apiUrl = `https://streamify.verandahighered.com/api/v1/get-hls-playlist?src_video=${url}`;
        console.log(`Fetching HLS Playlist: ${apiUrl}`);
        const response = await axios.get(apiUrl);


        if (response?.data?.status === "Processing") {
          console.warn("Video is still processing...");
        } else if (response?.data?.reference_object_url) {
          const referenceUrl = response?.data.reference_object_url;
          const errorMsg = response?.data.error;
          const status = response?.data.status;
          if (referenceUrl.endsWith('.m3u8')) {
            handleHlsSave(referenceUrl, lecture_id);

          }


          // Check if there's an error message

          if (errorMsg) {
            console.error(`Error: ${errorMsg}`);
            // Optionally handle the error message here (e.g., show a notification)
          }

          if (referenceUrl.endsWith('.mp4') && errorMsg && !status === 'pending' || !status ===  'processing') {
            console.log("MP4 detected, converting to HLS...");
            const hlsUrl = await fetchConvertMp4ToHls(referenceUrl);
            if (hlsUrl) {
              setHlsPlaylist(hlsUrl);
              console.log('hls url is ', hlsUrl)

            }
          } else if (referenceUrl.endsWith('.m3u8')) {
            console.log("HLS detected, using directly...");
            setHlsPlaylist(referenceUrl);
            console.log('hls url is ', referenceUrl)


          } else {
            throw new Error("Unsupported video format received.");
          }
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (error) {
        console.error("Failed to fetch HLS playlist:", error);
        setError("Failed to load video. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    const fetchConvertMp4ToHls = async (videoUrl) => {
      if (!videoUrl) return null;
      setLoading(true);
      setError(null);

      try {
        const apiUrl = `https://streamify.verandahighered.com/api/v1/convert-mp4-to-hls?src_video=${videoUrl}`;
        console.log(`Converting MP4 to HLS: ${apiUrl}`);
        const response = await axios.get(apiUrl);

        handleHlsSave(response?.data?.reference_object_url, lecture_id);

        if (response?.data?.status === "Processing") {
          console.warn("Video is still processing...");
          return null;
        } else if (response?.data?.reference_object_url) {
          return response?.data.reference_object_url;
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (error) {
        console.error("Failed to convert MP4 to HLS:", error);
        setError("Failed to convert video. Please try again later.");
        return null;
      } finally {
        setLoading(false);
      }
    };

    fetchHlsVideoPlaylist();


  }, [url]);


  useEffect(() => {
    const video = videoRef.current;
    if (!video || !getHlsVideo) return;

    if (getHlsVideo.endsWith('.m3u8') && Hls.isSupported()) {
      // Play HLS if available
      const hls = new Hls();
      hls.loadSource(getHlsVideo);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });

      return () => {
        hls.destroy();
      };
    } else {
      // Play MP4 if available
      video.src = getHlsVideo;
    }
  }, [getHlsVideo]);







  const toggleMute = () => {
    const video = videoRef.current
    if (video) {
      video.muted = !video.muted
      setVolume(video.muted ? 0 : 1)
    }
  }

  const changePlaybackRate = (rate) => {
    const video = videoRef.current
    if (video) {
      video.playbackRate = rate
      setPlaybackRate(rate)
    }
  }

  const toggleFullScreen = () => {
    const videoContainer = containerRef.current;
    if (!videoContainer) return;

    if (!isFullScreen) {
      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  }

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = Math.floor(time % 60)
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`
    } else if (minutes > 0) {
      return `${minutes}:${formattedSeconds}`
    } else {
      return `0:${formattedSeconds}`
    }
  }

  const toggleControlsVisibility = () => {
    setIsControlsVisible(!isControlsVisible)
  }


  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    if (url && url.endsWith('.m3u8') && Hls.isSupported()) {

      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });

      return () => {
        hls.destroy();
      };
    }
    else if (video) {
      video.src = url;
    }

    // Retrieve saved progress from localStorage
    const savedProgress = localStorage.getItem(`videoProgress_${url}`);
    if (savedProgress) {
      video.currentTime = parseFloat(savedProgress);
    }

    const handleLoadedMetadata = () => {
      localStorage.setItem(`videoDuration_${url}`, video.duration.toString());
    };

    const debouncedUpdateProgress = _.debounce(() => {
      localStorage.setItem(`videoProgress_${url}`, video.currentTime.toString());
    }, 5000);

    const updateProgress = () => {
      setCurrentTime(video.currentTime);
      debouncedUpdateProgress();
    };

    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    video.addEventListener('timeupdate', updateProgress);

    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      video.removeEventListener('timeupdate', updateProgress);
    };
  }, [url]);


  const handleHlsSave = async (hlsUrl, lectureId) => {
    try {

      await getSaveHlsUrl( video_content_type ?? 'recording', hlsUrl, lectureId);
      alert("HLS report saved successfully!");
    } catch (error) {
      console.error("Error saving HLS report:", error);
    }
  };


  const handleKeyPress = useCallback((e) => {
    if (e.code === 'Space') {
      e.preventDefault();
      togglePlay();
    } else if (e.code === 'ArrowRight') {
      e.preventDefault();
      seek(currentTime + 10);
    } else if (e.code === 'ArrowLeft') {
      e.preventDefault();
      seek(currentTime - 10);
    }
  }, [currentTime, togglePlay, seek]);


  useEffect(() => {
    let percentage = Math.floor((currentTime / duration) * 100);
    if (isNaN(percentage) || percentage == null || percentage == Infinity) {
      percentage = 0;
    }
    if (prevPercentage < percentage) {

      const throttledUpdate = _.throttle(async () => {

        const resp = await updateRecordingVideoCompletion(lecture_id, percentage);
        if (duration == 0) {
          return;
        }
        let seekTo = resp?.data[0].com_percentage;
        seekTo = (seekTo / 100) * duration;
        console.log("seekTo", seekTo);
        seek(seekTo);
        setPrevPercentage(percentage);
      }, 2000);
      // if percentage is zero then seek video to com_percentage value


      throttledUpdate();
    }
  }, [currentTime, duration]);

  useEffect(() => {

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentTime, duration, prevPercentage, lecture_id, updateRecordingVideoCompletion]);

  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    if (video) {
      setDuration(video.duration);
    }
  }
  return (
    <div ref={containerRef} className={`video-container ${isControlsVisible ? '' : 'controls-hidden'}`} onMouseEnter={() => setIsControlsVisible(true)} onMouseLeave={() => setIsControlsVisible(false)}>
      <video
        ref={videoRef}

        className="video-player"
        onClick={togglePlay}
        onError={onError}
        onLoadedMetadata={handleLoadedMetadata}
      />
      <div className="controls">
        <div className="control-row">
          <button onClick={togglePlay} className="control-button">
            {isPlaying ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="6" y="4" width="4" height="16"></rect>
                <rect x="14" y="4" width="4" height="16"></rect>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polygon points="5 3 19 12 5 21 5 3"></polygon>
              </svg>
            )}
          </button>
          {/* <button onClick={stop} className="control-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            </svg>
          </button> */}
          <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={(e) => seek(parseFloat(e.target.value))}
            className="seek-bar"
          />
          <div className="volume-control">
            <button onClick={toggleMute} className="control-button">
              {volume === 0 ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                  <line x1="23" y1="9" x2="17" y2="15"></line>
                  <line x1="17" y1="9" x2="23" y2="15"></line>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                  <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
                </svg>
              )}
            </button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={(e) => changeVolume(parseFloat(e.target.value))}
              className="volume-slider"
            />
          </div>
          <select
            value={playbackRate}
            onChange={(e) => changePlaybackRate(parseFloat(e.target.value))}
            className="playback-rate"
          >
            <option value="0.5">0.5x</option>
            <option value="1">1x</option>
            <option value="1.2">1.2x</option>
            <option value="1.5">1.5x</option>
            <option value="1.75">1.75x</option>
            <option value="2">2x</option>
          </select>
          <button onClick={toggleFullScreen} className="control-button">
            {isFullScreen ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
              </svg>
            )}
          </button>
        </div>
        <div className="control-row">
          <span className="time-display">{formatTime(currentTime)} / {formatTime(duration)}</span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  const { updateRecordingVideoCompletion, getSaveHlsUrl } = actions;
  return {
    getSaveHlsUrl: (content_type, hls_url, c_id) => dispatch(getSaveHlsUrl(content_type, hls_url, c_id)),
    updateRecordingVideoCompletion: (lecture_id, completion_per) => dispatch(updateRecordingVideoCompletion(lecture_id, completion_per)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomVideoPlayer);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import actions from '../../redux/actions';
import common from '../../common';
import Logo from '../../public/icons/IXP-Logo_White-.png';
import LogoSchoolOnWeb from '../../public/icons/schoolsonweb-logo-only.png';
import Contact from '../../public/icons/contact-us.svg';
import config from '../../config.js';
import Popup from 'reactjs-popup';
import ForgotImage from '../../public/icons/Group 270.svg';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import vguLogo from '../../public/images/vgu-logo1.jpg';
import './styles.css';


const pathList = ["/app/admin/form", "/app/admin/banner/", "/app/admin/widget-setting/1"];
class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			center_id: localStorage.getItem('center_id'),
			profile: {},
			image: '',
			redirectURL: "",
			is_faculty_admin: false,
			password: "",
			new_password: "",
			confirm_password: "",
			is_password_correct: 0,
			username: localStorage.getItem("username"),
			mobilenavigation: false,
			getCenterId: ''
		}
	}

	componentDidMount() {
		let centerid = localStorage.getItem("center_id");
		console.log("getcenterid", centerid);
		this.setState({ getCenterId: centerid })
		console.log(window.location.pathname)

		if (!pathList.includes(window.location.pathname)) {
			console.log("Amit" + pathList.includes(window.location.pathname))
			localStorage.setItem("is_widget", false)
		} else {
			localStorage.setItem("is_widget", true)
		}
		this.setState({ is_faculty_admin: localStorage.getItem("is_faculty_admin") })
		this.getProfile();
		this.getAdminProfile();
		this.redirectURL();
		setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0)
	}

	changePassword = async (username, password, new_password) => {
		const response = await this.props.changePassword(username, password, new_password, localStorage.getItem('lang'));

		this.setState({ open1: false });
	}

	getAdminProfile = async () => {
		const response = await this.props.getAdminProfile();
		if (response?.data.new_login === true) {
			this.show3();
		}
	}

	handleChangePassword = (e) => {
		this.setState({ password: e.target.value })
	}

	handleChangeNewPassword = (e) => {
		//e.target.value ===this.state.password?this.setState({confirm_password:true}):this.setState({confirm_password:false});
		this.setState({ new_password: e.target.value, confirm_password: '', is_password_correct: 0 })
	}

	handleChangeConfirmPassword = (e) => {
		this.setState({ confirm_password: e.target.value });

		e.target.value === this.state.new_password ? this.setState({ is_password_correct: 1 }) : this.setState({ is_password_correct: 0 });
	}

	show3 = () => this.setState({ open1: true });

	close = () => this.setState({ open1: false });

	getProfile = async () => {
		if (localStorage.getItem('token')) {
			const response = await this.props.getProfile(this.state.center_id);
			if (response !== undefined) {
				this.setState({ profile: response?.data, image: response?.data.logo });
			}
		}
	}

	redirectURL = () => {
		this.setState({ redirectURL: `${config.homePage}` });
	}


	changeTab = (e) => {
		setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
		this.setState({ mobilenavigation: false });
	}

	hideChange = () => this.setState({ hide: !this.state.hide })

	hideChange1 = () => this.setState({ hide1: !this.state.hide1 })

	hideChange2 = () => this.setState({ hide2: !this.state.hide2 })

	menuToggleActions() {
		const currentState = this.state.mobilenavigation;
		this.setState({ mobilenavigation: !currentState });
	}

	menuToggleClose() {
		this.setState({ mobilenavigation: false });
	}

	changeTabLink = () => {
		localStorage.setItem("is_widget", true)
		//window.location.href="/app/admin/form";
		setTimeout("window.location.href = '/app/admin/form'", 500);
	}

	render() {
		console.log(this.props.currentPage)
		const isEnabled = !this.state.password || !this.state.new_password || !this.state.confirm_password || !this.state.is_password_correct;
		return (
			<><Popup open={this.state.open1} closeOnDocumentClick onClose={this.close}
				className="change-password">
				<span className="close" onClick={this.close}>&#x2715;</span>
				<div className="change-password-notification-popup">

					<b>Hello</b> {this.state?.profile?.name},<br />
					Welcome onboard on Schools on Web<br /><br />
					Do you want to change your Password?
				</div>
				<div Style="display: flex;width: 100%;">
					<div Style="width: 60%">
						<img src={ForgotImage} alt="change-password" Style="width: 106%;" />
					</div>
					<div Style="padding-top:81px;">
						{<div className="fw_input fw_input2">

							<div className="input_field">
								<input type={this.state.hide ? "password" : "text"} placeholder='Current Password' name='password'
									value={this.state.password} onKeyPress={this.handleKeyPress2}
									onChange={this.handleChangePassword} />
								<div className="togglePassword" onClick={this.hideChange}>
									{this.state.hide === true ? <img src={Hide} alt="see" />
										: <img src={See} alt="hide" />}
								</div>
							</div>
							<div className="input_field">
								<input type={this.state.hide1 ? "password" : "text"} placeholder='New Password' name='new_password'
									value={this.state.new_password} onKeyPress={this.handleKeyPress2}
									onChange={this.handleChangeNewPassword} />
								<div className="togglePassword" onClick={this.hideChange1}>
									{this.state.hide1 === true ? <img src={Hide} alt="see" />
										: <img src={See} alt="hide" />}
								</div>
							</div>

							<div className="input_field" Style={this.state.is_password_correct ? "border-bottom: 1px solid green" : "border-bottom: 1px solid red"}>
								<input type={this.state.hide2 ? "password" : "text"} placeholder='Confirm Password' name='confirm_password'
									value={this.state.confirm_password} onKeyPress={this.handleKeyPress2}
									onChange={this.handleChangeConfirmPassword} />
								<div className="togglePassword" onClick={this.hideChange2}>
									{this.state.hide2 === true ? <img src={Hide} alt="see" />
										: <img src={See} alt="hide" />}
								</div>
							</div>

							<div className="button-class">
								<button onClick={() => this.changePassword(this.state.username, this.state.password, this.state.new_password)} disabled={isEnabled ? "disabled" : ""}>
									Change Password
								</button>
							</div>
						</div>}
					</div>
				</div>
			</Popup>

				<div className="bottomSidebar">
					<a href="#" className="toggleMenu" onClick={() => this.menuToggleActions()}>
						<span></span>
						<span></span>
						<span></span>
					</a>
				</div>


				<header className={this.state.mobilenavigation ? 'mobilenavigationActive' : ''}>
					<div className="CloseLink"><a href="#" onClick={() => this.menuToggleClose()}>&#x2715;</a></div>
					<Link className="side-logo" to={'/admin'} onClick={this.changeTab}>
						{this.state.profile.logo === null || this.state.profile.logo === ''
							? null : <div className="profile-dp"
								style={{ backgroundImage: "url(" + common.addFileUrl(this.state.image) + ")" }}></div>}
						{/* {this.state.profile.name} */}
					</Link>
					<div className="sidebar">




						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/institute'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/institute'
							|| this.props.currentPage === '/app/admin/institute/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/institute' || this.props.currentPage === '/app/admin/institute/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{/* {this.props.saveLanguageWiseData.institute} */}
							Institute
							{this.props.currentPage === '/app/admin/institute' || this.props.currentPage === '/app/admin/institute/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{/* {localStorage.getItem("is_widget") == "false" && <Link to={'/admin/college-info'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/college-info'
							|| this.props.currentPage === '/app/admin/college-info/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/college-info' || this.props.currentPage === '/app/admin/college-info/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							University
							{this.props.currentPage === '/admin/college-info' || this.props.currentPage === '/app/app/admin/college-info/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>} */}

						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/user_log'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/user_log'
							|| this.props.currentPage === '/app/admin/user_log/' ? 'selected' : ''}>
							Unique Users
						</Link>}


						{/* {!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/assessment-record'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/assessment-record/'
							|| this.props.currentPage === '/app/admin/assessment-record/' ? 'selected' : ''}>
							Assessment Record 
						</Link>} */}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/assessment-record'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/assessment-record/'
							|| this.props.currentPage === '/app/admin/assessment-record' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/assessment-record/' || this.props.currentPage === '/app/admin/assessment-record/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							Assessment Report
							{this.props.currentPage === '/app/admin/assessment-record/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/hls-report'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/hls-report/'
							|| this.props.currentPage === '/app/admin/hls-report' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/hls-report/' || this.props.currentPage === '/app/admin/hls-report/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							HLS Report
							{this.props.currentPage === '/app/admin/hls-report/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/organization-overview'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/organization-overview/'
							|| this.props.currentPage === '/app/admin/organization-overview' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/organization-overview/' || this.props.currentPage === '/app/admin/organization-overview/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							<span>
								Student Overview
							</span>
							{this.props.currentPage === '/app/admin/organization-overview'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}




						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/course-dashboard/'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/course-dashboard/'
							|| this.props.currentPage === '/app//admin/course-dashboard/' ? 'selected' : ''}>
							{this.props.currentPage === '/app//admin/course-dashboard/' || this.props.currentPage === '/app/admin/course-dashboard/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							Course Dashboard
							{this.props.currentPage === '/app//admin/course-dashboard/' || this.props.currentPage === '/app/admin/course-dashboard/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{localStorage.getItem("is_faculty") != 'true' && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/noticeboard'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/noticeboard'
							|| this.props.currentPage === '/app/admin/noticeboard/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/noticeboard' || this.props.currentPage === '/app/admin/noticeboard/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							Noticeboard
							{this.props.currentPage === '/admin/noticeboard' || this.props.currentPage === '/app/app/admin/noticeboard/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}


						{localStorage.getItem("is_faculty") != 'true' && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/dashboard'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/dashboard'
							|| this.props.currentPage === '/app/admin/dashboard/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/dashboard' || this.props.currentPage === '/app/admin/dashboard/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							Dashboard
							{this.props.currentPage === '/admin/dashboard' || this.props.currentPage === '/app/app/admin/dashboard/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/branches'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/branches'
							|| this.props.currentPage === '/app/admin/branches/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/branches' || this.props.currentPage === '/app/admin/branches/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.branch}
							{this.props.currentPage === '/app/admin/branches' || this.props.currentPage === '/app/admin/branches/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/branch-admins'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/branch-admins'
							|| this.props.currentPage === '/app/admin/branch-admins/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/branch-admins' || this.props.currentPage === '/app/admin/branch-admins/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.branch_admins}
							{this.props.currentPage === '/app/admin/branch-admins' || this.props.currentPage === '/app/admin/branch-admins/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/programs'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/programs'
							|| this.props.currentPage === '/app/admin/programs/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/programs' || this.props.currentPage === '/app/admin/programs/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.programs}
							{this.props.currentPage === '/app/admin/programs' || this.props.currentPage === '/app/admin/programs/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/admin-sessions'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/admin-sessions'
							|| this.props.currentPage === '/app/admin/admin-sessions/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/admin-sessions' || this.props.currentPage === '/app/admin/admin-sessions/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.program_sessions}
							{this.props.currentPage === '/app/admin/admin-sessions' || this.props.currentPage === '/app/admin/admin-sessions/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/classes'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/classes'
							|| this.props.currentPage === '/app/admin/classes/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/classes' || this.props.currentPage === '/app/admin/classes/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.classes}
							{this.props.currentPage === '/app/admin/classes' || this.props.currentPage === '/app/admin/classes/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/teachers'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/teachers'
							|| this.props.currentPage === '/app/admin/teachers/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/teachers' || this.props.currentPage === '/app/admin/teachers/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.teachers}
							{this.props.currentPage === '/app/admin/teachers' || this.props.currentPage === '/app/admin/teachers/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/students'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/students'
							|| this.props.currentPage === '/app/admin/students/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/students' || this.props.currentPage === '/app/admin/students/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.students}
							{this.props.currentPage === '/app/admin/students' || this.props.currentPage === '/app/admin/students/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/student-sem-promotion'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/student-sem-promotion'
							|| this.props.currentPage === '/app/admin/student-sem-promotion/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/student-sem-promotion' || this.props.currentPage === '/app/admin/student-sem-promotion/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.student_promotion_pabel}
							{this.props.currentPage === '/app/admin/student-sem-promotion' || this.props.currentPage === '/app/admin/student-sem-promotion/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/subjects'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/subjects'
							|| this.props.currentPage === '/app/admin/subjects/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/subjects' || this.props.currentPage === '/app/admin/subjects/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.subjects}
							{this.props.currentPage === '/app/admin/subjects/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{localStorage.getItem("is_faculty") != 'true' && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/certificate-upload'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/certificates'
							|| this.props.currentPage === '/app/admin/certificates/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/certificate-upload' || this.props.currentPage === '/app/admin/certificate-upload/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							Certificates
							{this.props.currentPage === '/admin/certificate-upload' || this.props.currentPage === '/app/app/admin/certificate-upload/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/attendance-report'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/attendance-report'
							|| this.props.currentPage === '/app/admin/attendance-report/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/attendance-report' || this.props.currentPage === '/app/admin/attendance-report/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							Attendance Report
							{this.props.currentPage === '/admin/attendance-report' || this.props.currentPage === '/app/app/admin/attendance-report/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}



						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/study-material'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/study-material'
							|| this.props.currentPage === '/app/admin/study-material/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/study-material' || this.props.currentPage === '/app/admin/study-material/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.study_material}
							{this.props.currentPage === '/app/admin/study-material' || this.props.currentPage === '/app/admin/study-material/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{/* <Link to={'/admin/lesson-plan'} onClick={this.changeTab} id={this.props.currentPage === '/admin/lesson-plan' 
						|| this.props.currentPage === '/admin/lesson-plan/' ? 'selected': ''}>
            {this.props.currentPage === '/admin/lesson-plan' || this.props.currentPage === '/admin/lesson-plan/'
							? <div className="crescent">
							<div className="shadowboard"></div>
							<div className="clipboard"></div>
						</div> : null}
						Lesson Plan
            {this.props.currentPage === '/admin/lesson-plan' || this.props.currentPage === '/admin/lesson-plan/'
							? <div className="crescent2">
							<div className="shadowboard"></div>
							<div className="clipboard2"></div>
						</div> : null}
					</Link> */}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/question-bank'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/question-bank'
							|| this.props.currentPage === '/app/admin/question-bank/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/question-bank' || this.props.currentPage === '/app/admin/question-bank/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.questions_bank}
							{this.props.currentPage === '/app/admin/question-bank' || this.props.currentPage === '/app/admin/question-bank/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{/* {!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" &&  <Link to={'/admin/questions'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/questions' 
						|| this.props.currentPage === '/app/admin/questions/' ? 'selected': ''}>
            {this.props.currentPage === '/app/admin/questions' || this.props.currentPage === '/app/admin/questions/'
							? <div className="crescent">
							<div className="shadowboard"></div>
							<div className="clipboard"></div>
						</div> : null}
						Import Questions
            {this.props.currentPage === '/app/admin/questions' || this.props.currentPage === '/app/admin/questions/'
							? <div className="crescent2">
							<div className="shadowboard"></div>
							<div className="clipboard2"></div>
						</div> : null}
					</Link>} */}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/practice-tests'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/practice-tests'
							|| this.props.currentPage === '/app/admin/practice-tests/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/practice-tests' || this.props.currentPage === '/app/admin/practice-tests/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.practice_tests}
							{this.props.currentPage === '/app/admin/practice-tests' || this.props.currentPage === '/app/admin/practice-tests/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/scheduled-tests'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/scheduled-tests'
							|| this.props.currentPage === '/app/admin/scheduled-tests/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/scheduled-tests' || this.props.currentPage === '/app/admin/scheduled-tests/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.scheduled_tests}
							{this.props.currentPage === '/app/admin/scheduled-tests' || this.props.currentPage === '/app/admin/scheduled-tests/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/assignment'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/assignment'
							|| this.props.currentPage === '/admin/assignment/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/assignment' || this.props.currentPage === '/app/admin/assignment/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.assigments}
							{this.props.currentPage === '/app/admin/assignment' || this.props.currentPage === '/app/admin/assignment/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/sessions'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/sessions'
							|| this.props.currentPage === '/app/admin/sessions/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/sessions' || this.props.currentPage === '/app/admin/sessions/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.sessions}
							{this.props.currentPage === '/app/admin/sessions' || this.props.currentPage === '/app/admin/sessions/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{/* {!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/calendar'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/calendar' 
						|| this.props.currentPage === '/app/admin/calendar/' ? 'selected': ''}>
						{this.props.currentPage === '/app/admin/calendar' || this.props.currentPage === '/app/admin/calendar/'
							? <div className="crescent">
							<div className="shadowboard"></div>
							<div className="clipboard"></div>
						</div> : null}
						Calendar
						{this.props.currentPage === '/app/admin/calendar' || this.props.currentPage === '/app/admin/calendar/'
							? <div className="crescent2">
							<div className="shadowboard"></div>
							<div className="clipboard2"></div>
						</div> : null}
					</Link>} */}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/report'} onClick={this.changeTab} id={this.props.currentPage === '/admin/report'
							|| this.props.currentPage === '/admin/report/' ? 'selected' : ''}>
							{this.props.currentPage === '/admin/report' || this.props.currentPage === '/admin/report/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.reports}
							{this.props.currentPage === '/admin/report' || this.props.currentPage === '/admin/report/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/curriculums'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/curriculums'
							|| this.props.currentPage === '/app/admin/curriculums/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/curriculums' || this.props.currentPage === '/app/admin/curriculums/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.curriculums}
							{this.props.currentPage === '/app/admin/curriculums' || this.props.currentPage === '/app/admin/curriculums/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}


						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}
						{localStorage.getItem("is_faculty") != 'true' && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/build-cache'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/build-cache'
							|| this.props.currentPage === '/app/admin/build-cache/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/build-cache' || this.props.currentPage === '/app/admin/build-cache/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{/* {this.props.saveLanguageWiseData.curriculums} */} Update Reports Data
							{this.props.currentPage === '/app/admin/build-cache' || this.props.currentPage === '/app/admin/build-cache/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}




						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/firebase-notifications'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/firebase-notifications'
							|| this.props.currentPage === '/app/admin/firebase-notifications/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/firebase-notifications' || this.props.currentPage === '/app/admin/firebase-notifications/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.notification_panel}
							{this.props.currentPage === '/app/admin/firebase-notifications' || this.props.currentPage === '/app/admin/firebase-notifications/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}
						{localStorage.getItem("is_faculty") != 'true' && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/open-group-listing'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/open-group-listing'
							|| this.props.currentPage === '/app/admin/open-group-listing/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/open-group-listing' || this.props.currentPage === '/app/admin/open-group-listing/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							Group Management
							{this.props.currentPage === '/app/admin/open-group-listing' || this.props.currentPage === '/app/admin/open-group-listing/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}


						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}
						{localStorage.getItem("is_widget") == "false" && <Link to={'/admin/elective-subjects/'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/elective-subjects/'
							|| this.props.currentPage === '/app//admin/elective-subjects/' ? 'selected' : ''}>
							{this.props.currentPage === '/app//admin/elective-subjects/' || this.props.currentPage === '/app/admin/elective-subjects/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							Elective Students
							{this.props.currentPage === '/app//admin/elective-subjects/' || this.props.currentPage === '/app/admin/elective-subjects/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}
						{localStorage.getItem("is_widget") == "false" && 
						<Link
						to="/admin/elective-subjects/"
						onClick={(e) => {
						  e.preventDefault(); // Prevent default React Router behavior if navigating externally
						  this.changeTab(); // Call your tab change function
						  window.location.href = "https://help.edulystventures.com/"; // Redirect to external page
						}}
						id={
						  this.props.currentPage === '/app/admin/elective-subjects/' ||
						  this.props.currentPage === '/app//admin/elective-subjects/' 
							? 'selected' 
							: ''
						}
					  >
						{this.props.currentPage === '/app//admin/elective-subjects/' || this.props.currentPage === '/app/admin/elective-subjects/' ? (
						  <div className="crescent">
							<div className="shadowboard"></div>
							<div className="clipboard"></div>
						  </div>
						) : null}
						Help & Support
						{this.props.currentPage === '/app//admin/elective-subjects/' || this.props.currentPage === '/app/admin/elective-subjects/' ? (
						  <div className="crescent2">
							<div className="shadowboard"></div>
							<div className="clipboard2"></div>
						  </div>
						) : null}
					  </Link>}


						{/* {localStorage.getItem("is_widget") == "false" && <Link to={'/admin/analytics/'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/analytics/'
							|| this.props.currentPage === '/app/admin/analytics/' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/analytics/' || this.props.currentPage === '/app/admin/analytics/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							Analytics
							{this.props.currentPage === '/app/admin/analytics/' || this.props.currentPage === '/app/admin/analytics/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>} */}


						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <hr />}




						{/* {!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to={'/admin/widget'} onClick={this.changeTab} id={this.props.currentPage === '/admin/widget' 
						|| this.props.currentPage === '/admin/widget/' ? 'selected': ''}>
						{this.props.currentPage === '/admin/widget' || this.props.currentPage === '/admin/widget/'
							? <div className="crescent">
							<div className="shadowboard"></div>
							<div className="clipboard"></div>
						</div> : null}
						Widgets
						{this.props.currentPage === '/admin/widget' || this.props.currentPage === '/admin/widget/'
							? <div className="crescent2">
							<div className="shadowboard"></div>
							<div className="clipboard2"></div>
						</div> : null}
					</Link>} */}

						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "true" && <Link to={'/admin/widget-setting/1'} onClick={this.changeTab} id={this.props.currentPage === '/admin/widget-setting/1'
							|| this.props.currentPage === '/admin/widget-setting/1/' ? 'selected' : ''}>
							{this.props.currentPage === '/admin/widget-setting/1' || this.props.currentPage === '/admin/widget-setting/1/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.dashboard_preview}
							{this.props.currentPage === '/admin/widget-setting/1' || this.props.currentPage === '/admin/widget-setting/1/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "false" && <Link to='' onClick={this.changeTabLink} id={this.props.currentPage === '/admin/widget-setting/1'
							|| this.props.currentPage === '/admin/widget-setting/1/' ? 'selected' : ''}>
							{this.props.currentPage === '/admin/widget-setting/1' || this.props.currentPage === '/admin/widget-setting/1/'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.dashboard_settings}
							{this.props.currentPage === '/admin/widget-setting/1' || this.props.currentPage === '/admin/widget-setting/1/'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == "true" && <Link to={'/admin/form'} onClick={this.changeTab} id={this.props.currentPage === '/app/admin/form'
							|| this.props.currentPage === '/app/admin/form' ? 'selected' : ''}>
							{this.props.currentPage === '/app/admin/form' || this.props.currentPage === '/app/admin/form'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.form}
							{this.props.currentPage === '/app/admin/form' || this.props.currentPage === '/admin/form'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == 'true' && <Link to={'/admin/banner'} onClick={this.changeTab} id={this.props.currentPage === '/admin/banner'
							|| this.props.currentPage === '/app/admin/banner' ? 'selected' : ''}>
							{this.props.currentPage === '/admin/banner' || this.props.currentPage === '/admin/banner'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.banner}
							{this.props.currentPage === '/app/admin/banner' || this.props.currentPage === '/app/admin/banner'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}

						{!this.state.is_faculty_admin && localStorage.getItem("is_widget") == 'true' && <Link to={'/admin/setting'} onClick={this.changeTab} id={this.props.currentPage === '/admin/setting'
							|| this.props.currentPage === '/app/admin/setting' ? 'selected' : ''}>
							{this.props.currentPage === '/admin/setting' || this.props.currentPage === '/admin/setting'
								? <div className="crescent">
									<div className="shadowboard"></div>
									<div className="clipboard"></div>
								</div> : null}
							{this.props.saveLanguageWiseData.institute_settings}
							{this.props.currentPage === '/app/admin/setting' || this.props.currentPage === '/app/admin/setting'
								? <div className="crescent2">
									<div className="shadowboard"></div>
									<div className="clipboard2"></div>
								</div> : null}
						</Link>}
						{this.state.getCenterId == 49 ?
							<a className="logo-noesis" href={this.state.redirectURL}><img src={vguLogo} alt="img" />	</a>
							: <a className="logo-noesis" href={this.state.redirectURL}>
								{/* <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/> */}


								{localStorage.getItem('logo_url') != 'undefined' ?
									<span><img src={localStorage.getItem('logo_url')} alt="Brand logo" /></span>
									:
									<span><img src='https://cdn.verandahighered.com/center-logos/sastra.png' alt="Brand logo" /></span>
								}
								<span>{this.props.saveLanguageWiseData.schools_on_web}</span>
							</a>
						}
						<div className="contact-us">
							{this.props.saveLanguageWiseData.reach_out_if_you_want_us_to_handle}
							<button>{this.props.saveLanguageWiseData.contact_us}</button>
							<img src={Contact} alt={this.props.saveLanguageWiseData.contact_us} />
						</div>
					</div>
				</header></>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		currentPage: state.commonReducer.currentPage,
		loading: state.commonReducer.loading,
		saveLanguageWiseData: state.commonReducer.languageData
	}
}

const mapDispatchToProps = (dispatch) => {
	const { setCurrentPage, getProfile, getAdminProfile, changePassword } = actions;
	return {
		setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
		getProfile: (center_id) => dispatch(getProfile(center_id)),
		getAdminProfile: () => dispatch(getAdminProfile()),
		changePassword: (username, otp, password, language) => dispatch(changePassword(username, otp, password, language)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);